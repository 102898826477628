import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { DataService } from 'src/app/services/data.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FilterService } from 'src/app/services/filter.service';
import { NewFilterService } from 'src/app/services/new-filter.service';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-comboBar-chart',
  templateUrl: './combo-bar-chart.component.html',
  styleUrls: ['./combo-bar-chart.component.scss']
})
export class ComboBarChartComponent implements OnInit, OnChanges {
  @ViewChild('barContainer', { static: true }) barContainer!: ElementRef
  @ViewChild('lineChart') lineChart!: ElementRef;
  // @ViewChild("prompt", { static: true }) prompt!: ElementRef;
  @Input('data') data: any
  @Input('pageKey') pageKey: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @ViewChild('fs') fs!: ElementRef;
  isActive = false;
  currentVisibleData: any = [];
  comboBarPopupData: any
  iconList: any[] = []
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  ComboBarData: any;
  props: any = {};
  divId: string = '';
  dataTurn: number = 0
  renderLinePopup: any = false
  initiateChart: boolean = false
  showBy: any; myType: any
  noData: boolean = false
  showMore:boolean = false


  private sliderSize = 300;
  private handleRadius = 6;
  private currentMin = 0;
  private currentMax = 100;
  private margins = {left:10,top:20}
  private orientation = 'vertical';

  @ViewChild("showMoreData", { static: true }) showMoreData!: ElementRef;
  constructor(public filterService: FilterService, private dataService: DataService, private container: ElementRef, private currency: CurrencyPipe, private newFilterService: NewFilterService, private dialogService :DialogService) {
    this.newFilterService.showBy.subscribe((value: any) => {
      this.showBy = value
      this.getComboBarData()

    })
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }




  totalTooltipData:any =[]
  height: any = 0
  
  //loade Method
  isLoading = false;
    async stop(ms: number): Promise<void> {
      return new Promise<void>(resolve => setTimeout(resolve, ms));
    }
    start() { this.isLoading = true;}
   

  showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any) {
    // console.log(myData,'`0---')
    if(myData[1][0][0] == 'shipments')
    {
      return myData[1][0][0] = 'Shipments'
    }
    this.height = 0
    this.dataTurn = 0
    this.height = chartHeight - myY
    this.dataTurn = chartWidth - myX

    if (this.props.lineDataType == "group") {
      this.comboBarPopupData = myData
      this.myType = myType
    } else {
      if (myData == undefined) {
        return
      }
      this.comboBarPopupData = myData
    }


    const copiedData = myData
    this.totalTooltipData = copiedData;
    this.comboBarPopupData = JSON.parse(JSON.stringify(copiedData));
    const tooltipData = copiedData;

    if (copiedData[1].length > 10) {
      // this.showMore = true;
      const data1 = copiedData[1].slice(0, 10);
      this.comboBarPopupData[1] = data1;
    } else {
      // this.showMore = false;
    }

    if (this.height < 250) {
      d3.select(this.lineChart.nativeElement).select("#d3comboBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('bottom', (this.height) - 64 + 'px')
        .style('top', 'unset')
    }
    else if (this.height > 250) {

      d3.select(this.lineChart.nativeElement).select("#d3comboBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 50 + 'px')
        .style('bottom', 'unset')
    }

    if (this.dataTurn < 250) {
      d3.select(this.lineChart.nativeElement).select("#d3comboBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('right', (this.dataTurn + 20) + 'px')
        .style('left', 'unset')
    }
    else if (this.dataTurn > 250) {

      d3.select(this.lineChart.nativeElement).select("#d3comboBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('left', (myX + 25) + 'px')
        .style('right', 'unset')
    }

    this.renderLinePopup = true
  }

  hideTooltip(myType: any): void {
    if(this.showMore) {}
     else {
      this.renderLinePopup = false
    }
  }


  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.divId = this.item.config?.div_id
    if (this.divId != '') {
      this.initiateCharts();
      this.getComboBarData();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getComboBarData();
    }
  }

  openDialog() {
    this.renderLinePopup = false
      this.dialogService.openDialog(this.showMoreData, '300px')
  }

  closeFullscreen(): void {
    this.isFullscreen = false;
    this.props.chartHeight = 500
    this.isActive = false;

    // if (document.exitFullscreen) {
    //   document.exitFullscreen();
    // } 
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }
  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }
//  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    // const mySection: any = document.getElementById(myChart.divId);
    const myClass = myChart.divId;
    // const width = mySection.clientWidth;
    const height = 500; // 300 is random height, height is fixed to data later on
    const mySvg = d3.select(this.barContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .attr('height', height)
      .style('background-color', 'white');

    mySvg.append('text').attr('id', 'noDataMessage' + myClass);

    const defs = mySvg.append('defs');
    // see bar for explanation - clip rect, drop shadow
     defs.append('clipPath').attr('id', 'alcBrushClip' + myClass)
     .append('rect').attr('id', 'alcBrushClipRect' + myClass);
 
     defs.append('clipPath').attr('id', 'alcClip' + myClass)
     .append('rect').attr('id', 'alcClipRect' + myClass);

    const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
      .attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    mySvg.append('g').attr('id', 'legendGroup' + myClass);


    mySvg.append('g').attr('class', 'brushBarGroup' + myClass);
    mySvg.append('g').attr('class', 'brushLineGroup' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass)
    mySvg.append('text').attr('id', 'yAxisLeftLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisRightLabel' + myClass);
    mySvg.append('g').attr('id', 'xAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('id', 'yAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
    mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
    mySvg.append('g').attr('id', 'yAxisRight' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('class', 'barGroup' + myClass);
    mySvg.append('g').attr('class', 'lineGroup' + myClass);
    mySvg.append('g').attr('id', 'sliderGroup' + myClass).attr('class', 'slider' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowCircleLeft' + myClass);
    mySvg.append('text').attr('class', 'legendArrowLeftItem' + myClass + ' legendArrowLeft' + myClass);
    mySvg.append('circle').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowCircleRight' + myClass);
    mySvg.append('text').attr('class', 'legendArrowRightItem' + myClass + ' legendArrowRight' + myClass);
    mySvg.append('line').attr('class', 'barMouseoverLine' + myClass + ' barMouseoverItem' + myClass);
    mySvg.append('rect').attr('class', 'barMouseoverRect' + myClass + ' barMouseoverItem' + myClass);
    mySvg.append('text').attr('class', 'barMouseoverText' + myClass + ' barMouseoverItem' + myClass);
    this.initiateChart = true
  }
// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart: any = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = myChart.props.chartHeight;
    let margins: any = {left: 90, right: 90, top: 100, bottom: 20, brush: 82, sides: 0, mid: 40, axis: 40};
    if (myChart.props.showBrush === false) {
      // different if brush
      margins.brush = 0;
      margins.mid = 0;
    }

      // Bar Slider
      myChart.barMin = undefined;
      myChart.barMax = undefined;
      // Line Slider
      myChart.lineMin = undefined;
      myChart.lineMax = undefined;


    mySvg.attr('height', height);
    // tick format as always (see bar chart)
    const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y"};
    const timePeriod = this.props.period;
    const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);

    const chartData: any = myChart.ComboBarData;
    // convert and sort date as always
    chartData.lineData.map((m: any) => m.date = new Date(m[myChart.props.dateVar]));
    chartData.lineData.sort((a: any, b: any) => d3.ascending(a.date, b.date));
    chartData.barData.map((m: any) => m.date = new Date(m[myChart.props.dateVar]));
    chartData.barData.sort((a: any, b: any) => d3.ascending(a.date, b.date));

    // gather the sources from lineData
    let sourceSet: any = new Set();
    chartData.lineData.forEach((d: any) => sourceSet.add(d[myChart.props.lineGroupVar]));
    sourceSet = Array.from(sourceSet);
    //if lineDataType is single, there is only one line so the variable is provided
    if(myChart.props.lineDataType === "single"){
      sourceSet = [myChart.props.lineVar];
    }
    // assign the colors
    const lineColors: any = {};
    sourceSet.forEach((d: any, i: any) => lineColors[d] = myChart.props.colors[i])
    lineColors[myChart.props.yAxisLabelRight] = '#1468E1';
    let lineDataset: any = [], tooltipData: any = {}, dateSet: any = new Set();

    let yMax: any = 0;
    if(myChart.props.lineDataType === "group"){
      // group behaves very like area line
      // empty array for each source
      sourceSet.forEach(() => {
        lineDataset.push([]);
      })
      // group by date
      let lineGroup: any = d3.group(chartData.lineData, (d: any) => d.date);
      lineGroup = Array.from(lineGroup);
      lineGroup.forEach((d: any) => {
        // for each date
        // add to set
        dateSet.add(d[0]);
        // set tooltip data
        tooltipData[d[0]] = [d[0], []]
        sourceSet.forEach((s: any, i: any) => {
          // for each source
          // calculate total
          const myTotal = d3.sum(d[1].filter((f: any) => f[myChart.props.lineGroupVar] === s), (t: any) => +t[myChart.props.lineVar]);
          // add a value to lineDataset
          lineDataset[i].push({
            date: d[0],
            value: myTotal === undefined ? 0 : myTotal,
            source: s
          })
        })
      })
      lineDataset.forEach((d: any) => {
        // now loop through line dataset and keep resetting max for all
        const myMax: any = d3.max(d, (m: any) => m.value);
        yMax = Math.max(yMax, myMax);
        // sort
        d = d.sort((a: any, b: any) => d3.ascending(a.date, b.date));
        // map out whether there is movement up/down/none (for tooltip)
        d.map((m: any, i: any) => m.movement = i === 0 ? null : m.value > d[i-1].value ? "up"
          : (m.value === d[i - 1].value ? "none" : "down"));
        d.forEach((x: any) => {
          // add source, value, movement, colour to tooltip data
          tooltipData[x.date][1].push([x.source,x.value,x.movement,lineColors[x.source]]);
        })
      })
    } else {
      // slightly different for single line mode (not much)
      let myDataset: any = [];
      // group by date
      let lineGroup: any = d3.group(chartData.lineData, (d: any) => d.date);
      lineGroup = Array.from(lineGroup);
      lineGroup.forEach((d: any, i: any) => {
        // add to date set
        dateSet.add(d[0]);
        // get total by line var
        const myTotal = d3.sum(d[1], (s: any) => +s[myChart.props.lineVar]);
        d.value = myTotal;
        // calculate movement
        const myMovement = i === 0 ? null : d.value > myDataset[i-1].value ? "up"
          : (d.value === myDataset[i - 1].value ? "none" : "down");
        // add to dataset
        myDataset.push({
          date: d[0],
          value: myTotal,
          source: myChart.props.lineVar,
          movement: myMovement
        })

        // add to tooltip data
        tooltipData[d[0]] =[d[0],[myChart.props.lineVar,myTotal,myMovement,lineColors[myChart.props.lineVar]]];
      })
      // sort dates and add to lineData
      myDataset = myDataset.sort((a: any, b: any) => d3.ascending(a.date, b.date));
      lineDataset.push(myDataset);
      const lineMax: any = d3.max(myDataset, (m: any) => m.value);
      // keep resetting max till all data done
      yMax = Math.max(yMax, lineMax);
    }
    //fix when no data (soon to be redundant)
    if(lineDataset.length > 0 && lineDataset[0].length === 0){lineDataset = []}
    // now bar data
    let barDataset: any = [];
    // group by date
    let barGroup: any = d3.group(chartData.barData, (d: any) => d.date);
    barGroup = Array.from(barGroup);
    barGroup.forEach((d: any) => {
      // for each date, calculate total and build dataset
      const myTotal = d3.sum(d[1], (s: any) => +s[myChart.props.barVar]);
      barDataset.push({
        date: d[0],
        value: myTotal,
        source: myChart.props.barVar
      })
    })
    // sort and add movement
    barDataset = barDataset.sort((a: any, b: any) => d3.ascending(a.date, b.date));
    barDataset.map((m: any, i: any) => m.movement = i === 0 ? null : m.value > barDataset[i-1].value ? "up"
      : (m.value === barDataset[i - 1].value ? "none" : "down"));
    // get max
    const barMax: any = d3.max(barDataset, (m: any) => m.value);
    // create dateSet into sorted array of dates
    dateSet = Array.from(dateSet).map((m: any) => m = new Date(m));
    dateSet = dateSet.sort((a: any, b: any) => d3.ascending(a, b));
    // tickValues depending on axisFormatting - see bar chart for full explanation, same for all
    const tickValues = myChart.props.d3AxisFormatting === true ? null : dateSet;
    const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
    // using a linear scale so setting bar with  a min
    let barWidth = Math.min(margins.axis - 5,(width - margins.left - margins.right)/((barDataset.length-1) * 2))
    // x scales (all and scalle as normal)
    // brush a bit different so the bars fit
    const xExtent: any = d3.extent(dateSet,(d: any) => d);
    const xScaleBrush: any = d3.scaleTime().range([0, width - margins.left - margins.right- barWidth]).domain(xExtent);
    const xScaleAll: any = d3.scaleTime().range([0, width - margins.left - margins.right ]).domain(xExtent);
    const xScale: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    // y scales - since we have right, need 4
    const yScale: any = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    const yScaleRight: any = d3.scaleLinear().domain([0, barMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
    const yScaleBrush: any = d3.scaleLinear().domain([0, yMax]).range([margins.brush - margins.mid - 5,0]);
    const yScaleBrushRight: any = d3.scaleLinear().domain([0, barMax]).range([margins.brush - margins.mid - 5, 0]);
    // calculating brushStartX - same in all but slightly different variables
    // see bar chart for full explanation
    let startingVisibleRows: any = parseInt(String((width - margins.left - margins.right) / 25), 10);
    const maxDataLength: any = barDataset.length;
    startingVisibleRows = startingVisibleRows > maxDataLength ? maxDataLength : startingVisibleRows;
    const brushStartX = barDataset.length === 0 ? 0 : (1-(startingVisibleRows/maxDataLength)) * (width - margins.left - margins.right);
    // define lines
    const line: any = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScale(d.date))
      .y((d: any) =>  yScale(d.value));

    const lineBrush: any =  d3.line()
      .x((d: any) => xScaleBrush(d.date))
      .y((d: any) =>  yScaleBrush(d.value))
    // define brush
    const brush: any = d3.brushX()
      .handleSize(10)
      .extent([[0, 0], [width - margins.left - margins.right, margins.brush - margins.mid]])
      .on('start brush end', brushed);
    // mouseover line
    mySvg.select('.barMouseoverLine' + myClass)
      .attr("visibility", "hidden")
      .attr("x2", width - margins.right)
      .attr('stroke', '#1468E1')
      .attr('stroke-dasharray', '2,2');
    // additional mouseover rect and text on right for this chart only
    mySvg.select('.barMouseoverRect' + myClass)
      .attr("visibility", "hidden")
      .attr("x", width - margins.right + 2)
      .attr('width', 40)
      .attr('height', 12)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('fill', '#1468E1');

    mySvg.select('.barMouseoverText' + myClass)
      .attr("visibility", "hidden")
      .attr("x", width - margins.right + 2 + 20)
      .attr('text-anchor', "middle")
      .style('font-family', 'Poppins')
      .style('font-weight', 500)
      .style('font-size', 10)
      .attr('dy', 4)
      .attr('fill', 'white');
    // soon to be redundant
    mySvg.select('#noDataMessage' + myClass)
      .attr("x", margins.left + (width - margins.left - margins.right)/2)
      .attr("y", -8 + margins.top + (height - margins.top + margins.mid - margins.brush - margins.bottom)/2)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '16px')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle')
      .text(lineDataset.length === 0 && barDataset.length === 0 ? "There is no data for this time period" : "");
    // partial period
    mySvg.select('.partialPeriodLine' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("x1", width - margins.right)
      .attr("x2", width - margins.right)
      .attr("y1", 75)
      .attr("y2", height - margins.bottom - margins.brush )
      .attr('stroke', '#1363DF')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray','2,2');

    mySvg.select('.partialPeriodLineLabel' + myClass)
      .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
      .attr("transform", "translate(" + (width - margins.right) + "," +
        (40) + ")")
      .attr("text-anchor", "middle")
      .attr("dy", 0)
      .attr('font-size', '8px')
      .attr('font-family', 'Poppins')
      .attr('font-weight', '400')
      .attr('fill', '#1363DF')
      .text('Partial period')
      .call(wrap, 45);
    // axis labels
    mySvg.select('#yAxisLeftLabel' + myClass)
      .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(' + (margins.left - 40) + ',' + (margins.top-42) + ')')
      .attr('text-anchor', 'end')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '8px')
      .attr('dy', '0')
      .text(myChart.props.yAxisLabelLeft)
      .call(wrap,40);

    mySvg.select('#yAxisRightLabel' + myClass)
      .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(' + (width - (margins.right - 40)) + ',' + (margins.top - 42) + ')')
      .attr('text-anchor', 'start')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '8px')
      .attr('dy', '0')
      .text(myChart.props.yAxisLabelRight)
      .call(wrap,40);
      // clip rect
    mySvg.select('#alcClipRect' + myClass)
    .style('width', width -  margins.right - margins.left + barWidth + 40)
    .style('height', margins.mid+2)
    .attr('transform', 'translate('+ (margins.left - (barWidth/2) -20) + ',' + (height - margins.bottom-30) + ')');
    // clip rect
    mySvg.select('#alcBrushClipRect' + myClass)
      .style('width', width -  margins.right - margins.left + barWidth + 3)
      .style('height', height - margins.brush - 15 - margins.mid - 55)
      .attr('transform', 'translate('+ (margins.left - (barWidth/2)) + ',' + (margins.top -10) + ')');
    // same as others (see bar chart) - different settings
    // actions depending on whether we want a brush or not
    if(myChart.props.showBrush === true){
      mySvg.select('#brushGroup' + myClass)
        .attr('transform', 'translate(' + (margins.left) + ',' + (height - margins.brush - 10 + margins.mid) + ')')
        .call(brush)
        .call(brush.move, [brushStartX, width - margins.left - margins.right]);

      mySvg.selectAll('.handleLines' + myClass)
        .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
        .attr('y1', height - margins.brush + margins.mid - 10 + ((margins.brush - margins.mid ) - 12) / 2)
        .attr('y2', height - margins.brush  + margins.mid - 10 + 12 + ((margins.brush - margins.mid ) - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(' + (margins.left) + ',0)');

      mySvg.selectAll('#brushGroup' + myClass)
        .selectAll('.selection')
        .attr('fill', '#A0A0A0')
        .attr('fill-opacity', '0.2')
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');
    } else {

      mySvg.select('#brushGroup' + myClass).html("")

      mySvg.selectAll('.handleLines' + myClass)
        .style('stroke-width', '0')

      mySvg.select('#brushLinePath' + myClass)
        .attr('d', "")
    }
    // y axes
    mySvg.select('#yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
      .attr('transform', 'translate(' + (margins.left - margins.axis) + ',' + margins.top + ')');

    mySvg.selectAll('#yAxis' + myClass + ' line')
      .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
      .attr('x1', '0')
      .attr('x2', width - margins.right - margins.left)
      .style('stroke', '#F0F3F6')
      .style('stroke-width', 1);

    mySvg.select('#yAxisRight' + myClass)
      // @ts-ignore
      .call(d3.axisRight(yScaleRight).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
      .attr('transform', 'translate(' + (width - margins.right + margins.axis) + ',' + margins.top + ')');

    mySvg.selectAll('#yAxisRight' + myClass + ' line')
      .style('display', 'none');

    mySvg.selectAll('#yAxisRight' + myClass + ' text')
      .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));

    mySvg.selectAll('#yAxis' + myClass + ' text')
      .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));
    // brush chart
    const brushBarChartGroup = mySvg
      .select('.brushBarGroup' + myClass)
      .attr('clip-path', 'url(#alcClip' + myClass + ')')
      .selectAll('.brushBGroup' + myClass)
     .data(myChart.props.showBrush === true ? barDataset : [])
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'brushBGroup' + myClass);
        enter.append('path').attr('class', 'barRect');
        return enter;
      });

    // bar is a path not a rect so that the end can be rounded
    brushBarChartGroup.select( '.barRect')
    .attr('d', (d: any) => {
      let barHeight = yScaleBrushRight(0) - yScaleBrushRight(d.value);
      let curveHeight = barWidth/2;
      barHeight = barHeight < 5 && barHeight !== 0 ? 5 : barHeight;
      curveHeight = curveHeight > barHeight ? barHeight : curveHeight;
      return 'M0,' + barHeight + 'L0,' + curveHeight + ' Q0,0 ' + (barWidth/2) + ',0 L' + (barWidth/2)
      + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + curveHeight + ' L' +  barWidth + ',' + barHeight +  'Z'
    })
      // .attr('d', (d: any) => 'M0,' + (yScaleBrushRight(0) - yScaleBrushRight(d.value)) + 'L0,4 Q0,0 4,0 L' + (barWidth-4)
      //   + ',0 Q' + barWidth + ',0 ' + barWidth + ',4 L' +  barWidth + ',' + (yScaleBrushRight(0) - yScaleBrushRight(d.value)) +  'Z')
      .attr('fill','#1468E1')
      .attr('transform', (d: any) => 'translate(' + (margins.left + xScaleBrush(d.date))
        + ',' + (height - margins.brush - margins.bottom + 15 + margins.mid + yScaleBrushRight(d.value)) + ')')
    // brush line
    const brushChartGroup = mySvg
      .select('.brushLineGroup' + myClass)
      .attr('clip-path', 'url(#alcClip' + myClass + ')')
      .selectAll('.brushCGroup' + myClass)
      .data(myChart.props.showBrush === true ? lineDataset : [])
      .join(function(group: any): any {
        const enter = group.append('g').attr('class', 'brushCGroup' + myClass);
        enter.append('path').attr('class', 'brushLine');
        return enter;
      });

    brushChartGroup.select('.brushLine')
      .attr('d', lineBrush)
      .attr('fill', 'none')
      .attr('stroke-width', '2')
      .attr('stroke', (d: any) => lineColors[d[0].source])
      .attr('transform', 'translate(' + (margins.left + (barWidth/2)) + ',' + (height - margins.brush - 5 + margins.mid) + ')');

    // legend functionality with buttons
    // same on combo/line/area line
    // see line chart for comments/explanations
    let currentLegendSet: any = [myChart.props.yAxisLabelRight];
    currentLegendSet = currentLegendSet.concat(sourceSet);
    let currentLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
    let legendIndexLeft = 0;
    let legendIndexRight = currentLegendItems.length;
    const legendWidth = d3.sum(currentLegendItems, (s: any) => measureWidth(s, 12)  + 40)
    mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
    const legendExtraWidth = 80;
    if (legendWidth >= (width - legendExtraWidth)){
      currentLegendItems = [];
      let currentWidth = 0;
      currentLegendSet.forEach((d: any, i: any) => {
        currentWidth += (measureWidth(d.toUpperCase(), 12) + 60);
        if (currentWidth < (width - legendExtraWidth)){
          legendIndexRight = i;
          currentLegendItems.push(d);
        }
      });
    }
    mySvg.selectAll('.legendArrowLeftItem' + myClass)
    .attr('visibility', currentLegendItems.length === currentLegendSet.length || legendIndexLeft === 0? "hidden" : "visible");
    mySvg.selectAll('.legendArrowRightItem' + myClass)
    .attr('visibility', currentLegendItems.length === currentLegendSet.length ? "hidden" : "visible");
    // more legend - see line
    mySvg.select('.legendArrowCircleLeft' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft -= 1;
        legendIndexRight -= 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'visible');
        if (legendIndexLeft === 0){
          mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowLeft' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', 21)
      .attr('y', 35 + 5.5)
      .text('<');
    // more legend - see line
    mySvg.select('.legendArrowCircleRight' + myClass)
      .attr('r', 10)
      .attr('fill', 'transparent')
      .attr('stroke', '#E8EAEE')
      .attr('cursor', 'pointer')
      .attr('cx', width - 21)
      .attr('cy', 35)
      .on('click', () => {
        legendIndexLeft += 1;
        legendIndexRight += 1;
        const myFilteredLegendItems = JSON.parse(JSON.stringify(currentLegendSet));
        currentLegendItems = myFilteredLegendItems.filter((f: any, i: any) => (i >= legendIndexLeft && i <= legendIndexRight));
        drawLegend(currentLegendItems);
        mySvg.selectAll('.legendArrowLeftItem' + myClass).attr('visibility', 'visible');
        if (legendIndexRight === (currentLegendSet.length - 1)){
          mySvg.selectAll('.legendArrowRightItem' + myClass).attr('visibility', 'hidden');
        }
      });
    // more legend - see line
    mySvg.select('.legendArrowRight' + myClass)
      .attr('fill', '#1363DF')
      .attr('font-weight', 'bold')
      .attr('font-size', '16')
      .attr('text-anchor', 'middle')
      .attr('pointer-events', 'none')
      .attr('x', width - 21)
      .attr('y', 35 + 5.5)
      .text('>');

    drawLegend(lineDataset.length === 0 ? [] : currentLegendItems)
    drawChart();

    function drawChart(){
      
      const yScale: any             = d3.scaleLinear().domain([ myChart.lineMin || 0 , myChart.lineMax || yMax   ]).range([height - margins.top - margins.bottom - margins.brush, 0]);
      const yScaleRight: any        = d3.scaleLinear().domain([ myChart.barMin  || 0 , myChart.barMax  || barMax]).range([height - margins.top - margins.bottom - margins.brush, 0]);
      const yScaleBrush: any        = d3.scaleLinear().domain([ myChart.lineMin || 0 , myChart.lineMax || yMax   ]).range([margins.brush - margins.mid - 5,0]);
      const yScaleBrushRight: any   = d3.scaleLinear().domain([ myChart.barMin  || 0 , myChart.barMax  || barMax]).range([margins.brush - margins.mid - 5, 0]);
   // y axes
   mySvg.select('#yAxis' + myClass)
   // @ts-ignore
   .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
   .attr('transform', 'translate(' + (margins.left - margins.axis) + ',' + margins.top + ')');

 mySvg.selectAll('#yAxis' + myClass + ' line')
   .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
   .attr('x1', '0')
   .attr('x2', width  - margins.left -10)
   .style('stroke', '#F0F3F6')
   // .style('stroke-width', 1);

 mySvg.select('#yAxisRight' + myClass)
   // @ts-ignore
   .call(d3.axisRight(yScaleRight).tickSizeOuter(0).ticks(3, myChart.props.yAxisFormat))
   .attr('transform', 'translate(' + (width - margins.right + margins.axis) + ',' + margins.top + ')');

 mySvg.selectAll('#yAxisRight' + myClass + ' line')
   .style('display', 'none');

 mySvg.selectAll('#yAxisRight' + myClass + ' text')
   .text((d: any) =>  d3.format(myChart.props.yAxisFormat)(d));
   // .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));

 mySvg.selectAll('#yAxis' + myClass + ' text')
   .text((d: any) =>  d3.format(myChart.props.yAxisFormat)(d));
   // .text((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormat)(d));
      const line: any = d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScale(d.date))
      .y((d: any) =>  yScale(d.value));

      const lineBrush: any =  d3.line()
      .curve(d3.curveMonotoneX)
      .x((d: any) => xScaleBrush(d.date))
      .y((d: any) =>  yScaleBrush(d.value))
   // brush chart
   const brushBarChartGroup = mySvg
   .select('.brushBarGroup' + myClass)
   .attr('clip-path', 'url(#alcClip' + myClass + ')')
   .selectAll('.brushBGroup' + myClass)
  .data(myChart.props.showBrush === true ? barDataset : [])
   .join(function(group: any): any {
     const enter = group.append('g').attr('class', 'brushBGroup' + myClass);
     enter.append('path').attr('class', 'barRect');
     return enter;
   });

 // bar is a path not a rect so that the end can be rounded
 brushBarChartGroup.select( '.barRect')
 .attr('d', (d: any) => {
  let barHeight = yScaleBrushRight(0) - yScaleBrushRight(d.value);
  let curveHeight = barWidth/2;
  barHeight = barHeight < 5 && barHeight !== 0 ? 5 : barHeight;
  curveHeight = curveHeight > barHeight ? barHeight : curveHeight;
  return 'M0,' + barHeight + 'L0,' + curveHeight + ' Q0,0 ' + (barWidth/2) + ',0 L' + (barWidth/2)
  + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + curveHeight + ' L' +  barWidth + ',' + barHeight +  'Z'
})
  //  .attr('d', (d: any) => 'M0,' + (yScaleBrushRight(0) - yScaleBrushRight(d.value)) + 'L0,4 Q0,0 4,0 L' + (barWidth-4)
  //    + ',0 Q' + barWidth + ',0 ' + barWidth + ',4 L' +  barWidth + ',' + (yScaleBrushRight(0) - yScaleBrushRight(d.value)) +  'Z')
   .attr('fill','#1468E1')
   .attr('transform', (d: any) => 'translate(' + (margins.left + xScaleBrush(d.date))
     + ',' + (height - margins.brush - margins.bottom + 15 + margins.mid + yScaleBrushRight(d.value)) + ')')
 // brush line
 const brushChartGroup = mySvg
   .select('.brushLineGroup' + myClass)
   .attr('clip-path', 'url(#alcClip' + myClass + ')')
   .selectAll('.brushCGroup' + myClass)
   .data(myChart.props.showBrush === true ? lineDataset : [])
   .join(function(group: any): any {
     const enter = group.append('g').attr('class', 'brushCGroup' + myClass);
     enter.append('path').attr('class', 'brushLine');
     return enter;
   });

 brushChartGroup.select('.brushLine')
   .attr('d', lineBrush)
   .attr('fill', 'none')
   .attr('stroke-width', '2')
   .attr('stroke', (d: any) => lineColors[d[0].source])
   .attr('transform', 'translate(' + (margins.left + (barWidth/2)) + ',' + (height - margins.brush - 5 + margins.mid) + ')');

   


      // set selection visibility
      mySvg.selectAll(".selection")
        .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible');
      // set data for Angular team
      myChart.currentVisibleData = [barDataset, lineDataset, xScale.domain()];
      // reset x axis styles
      mySvg.selectAll('#xAxis' + myClass + ' line')
        .attr('display', 'none');

      mySvg.selectAll('.axis' + myClass + ' path')
        .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
        .style('stroke', '#E8EAEE');

      mySvg.selectAll('.axis' + myClass + ' text')
        .style('font-family', 'Poppins')
        .style('font-weight', myChart.props.axisFontWeight)
        .style('font-size', myChart.props.axisFontSize)

      mySvg.selectAll('#xAxis' + myClass + ' text')
        .style("text-anchor", "middle")
        .attr("dx", -5)
        .attr("dy", 5)
        .attr("transform", "");
      // labels - rotation - same for bar/line/combo/arealine - see bar for comments/explanation
      let ticksArray = tickValues === null ? xScale.ticks() : tickValues.filter((f: any) => f >= xScale.domain()[0] && f <= xScale.domain()[1]);
      ticksArray = ticksArray.sort((a: any, b: any) => d3.ascending(a,b));
      const xBandwidth: any = d3.min(ticksArray, (m: any, i: any) => i === ticksArray.length - 1
        ? width - margins.chartRight - margins.chartLeft : xScale(ticksArray[i+1]) - xScale(m) )
      const maxTextWidth = getMaxTextWidth();
      if(maxTextWidth > (xBandwidth - 5)){
        let myFontSize = xBandwidth < myChart.props.axisFontSize + 2 ? xBandwidth - 2 : myChart.props.axisFontSize;
        mySvg.selectAll('#xAxis' + myClass + ' text')
          .style("text-anchor", (myChart.props.d3AxisFormatting === true ? "middle" : "end" ))
          //@ts-ignore
          .attr("dy",  (myChart.props.d3AxisFormatting === true ? 5 : 1 ))
          .style("font-size", myFontSize)
          //@ts-ignore
          .attr("transform", "rotate(" + (myChart.props.d3AxisFormatting === true ? 0 : -45 ) + ")");
      }
      function getMaxTextWidth(){
        let maxTextWidth =  0;
        mySvg.selectAll('#xAxis' + myClass + ' text').each(function(){
          //@ts-ignore
          const myNode = d3.select(this).node().getBBox();
          maxTextWidth = Math.max(maxTextWidth, myNode.width)
        })
        return maxTextWidth
      }
      // bar group
      const barGroup = mySvg
        .select('.barGroup' + myClass)
        .attr('clip-path', 'url(#alcBrushClip' + myClass + ')')
        .selectAll('.barRectGroup' + myClass)
        .data(barDataset)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'barRectGroup' + myClass);
          enter.append('path').attr('class', 'barRect');
          return enter;
        });
      barGroup.on('mousemove', function (event: any, d: any) {
        // make all bars opaque
        mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 0.6);
        // @ts-ignore - except this one
        d3.select(this).attr("opacity", 1);
        // make all lines opaque
        mySvg.selectAll('.lineGroup' + myClass).attr("opacity", 0.4);
        // get tooltip data and show tooltip
        const barTooltip = [d.date, [[myChart.props.yAxisLabelRight, d.value, d.movement, '#1468E1']]]
        myChart.showTooltip('bar', barTooltip, event.offsetX, event.offsetY, width, height);
        // bar is RIGHT axis, so make that prominent
        mySvg.select('#yAxisRight' + myClass).selectAll("text").style("font-size", 11).style("font-weight", "bold").style("fill", "black");
        mySvg.select('#yAxisRight' + myClass).selectAll("path").style("stroke-width", '3');
        // show mouseover line, rect and text
        mySvg.select('.barMouseoverLine' + myClass)
          .attr("visibility", "visible")
          .attr("x1", margins.left + xScale(d.date))
          .attr("y1", margins.top + yScaleRight(d.value))
          .attr("y2", margins.top + yScaleRight(d.value));

        mySvg.select('.barMouseoverRect' + myClass)
          .attr("visibility", "visible")
          .attr("y", margins.top + yScaleRight(d.value) - 6);

        mySvg.select('.barMouseoverText' + myClass)
          .attr("visibility", "visible")
          .attr("y", margins.top + yScaleRight(d.value))
          .text(d3.format(".2s")(d.value))
      })
        .on('mouseout', () => {
          // back to original settings
          mySvg.selectAll('.barMouseoverItem' + myClass).attr("visibility", "hidden");
          mySvg.select('#yAxisRight' + myClass).selectAll("text").style("font-size", 10).style("font-weight", "normal").style("fill", " #737D88");
          mySvg.select('#yAxisRight' + myClass).selectAll("path").style("stroke-width", '1');
          mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 1);
          mySvg.selectAll('.lineGroup' + myClass).attr("opacity", 1);
          myChart.hideTooltip('bar');
        });

      // bars are paths not rects
      barGroup.select( '.barRect')
      .attr('d', (d: any) => {
        let barHeight = yScaleRight(0) - yScaleRight(d.value);
        let curveHeight = barWidth/2;
        barHeight = barHeight < 5 && barHeight !== 0 ? 5 : barHeight;
        curveHeight = curveHeight > barHeight ? barHeight : curveHeight;
        return 'M0,' + barHeight + 'L0,' + curveHeight + ' Q0,0 ' + (barWidth/2) + ',0 L' + (barWidth/2)
        + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + curveHeight + ' L' +  barWidth + ',' + barHeight +  'Z'
      })
        .attr('fill','#1468E1')
        .attr('transform', (d: any) => 'translate(' + (margins.left  + xScale(d.date) - (barWidth/2) )
          + ',' + (margins.top + yScaleRight(d.value)) + ')');

      // now for the lines
      const lineGroup = mySvg
        .select('.lineGroup' + myClass)
        .attr('clip-path', 'url(#alcBrushClip' + myClass + ')')
        .selectAll('.linePathGroup' + myClass)
        .data(lineDataset)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'linePathGroup' + myClass);
          enter.append('path').attr('class', 'linePath');
          enter.append('g').attr('class', 'lineDotGroup');
          return enter;
        });

      lineGroup
        .attr('id', (d: any, i: any) => "line" + i)
        .on("mousemove", function (event: any, d: any) {
          // same mousemove policy on all lines
          // get index of closest date (see area line for full explanation)
          const positionDate = xScale.invert(event.offsetX - margins.left);
          let closest: any = dateSet.findIndex((f: any) => new Date(f) >= positionDate);
          if(closest > 0){
            const leftMinutes = d3.timeMinute.count(dateSet[closest - 1], positionDate);
            const rightMinutes = Math.abs(d3.timeMinute.count(dateSet[closest], positionDate));
            if(leftMinutes < rightMinutes){
              closest -= 1;
            }
          } else if(closest === -1){
            closest = dateSet.length - 1;
          }
          // @ts-ignore
          const myId = this.id;
          //show line dots, background dots, paths
          mySvg.selectAll('.lineDot').attr("r", 3);
          mySvg.selectAll('.backgroundDot').attr("r", 3);
          mySvg.selectAll('.linePath').attr("stroke-width", 3);
          // make bars opaque
          mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 0.2);
          // lines are LEFT axis so make this prominent
          mySvg.select('#yAxis' + myClass).selectAll("text").style("font-size", 11).style("font-weight", "bold").style("fill", "black");
          mySvg.select('#yAxis' + myClass).selectAll("path").style("stroke-width", '3');
          // make this path thicker
          mySvg.select("path#" + myId).attr("stroke-width", 6);
          // and these dots bigger
          mySvg.selectAll("circle#dot" + closest).attr("r", 4.5)
          // show tooltip
          const mySource = sourceSet[myId.split("line")[1]];
          const tooltipContents = tooltipData[String(dateSet[closest])];
          if( typeof tooltipContents[1]?.[0]  == 'string'){
            tooltipContents[1]=[tooltipContents[1]]
          }else{
            tooltipContents[1] = tooltipContents[1].sort((a: any,b: any) =>  a[0] == mySource ? -1 : b[0] == mySource ? 1 : 0);
          }
          myChart.showTooltip('line', tooltipContents,event.offsetX, event.offsetY, width, height);
        })
        .on('mouseout', () => {
          // back to original settings
          mySvg.select('#yAxis' + myClass).selectAll("text").style("font-size", 10).style("font-weight", "normal").style("fill", " #737D88");
          mySvg.select('#yAxis' + myClass).selectAll("path").style("stroke-width", '1');
          mySvg.selectAll('.barRectGroup' + myClass).attr("opacity", 1);
          mySvg.selectAll('.lineDot').attr("r", 3);
          mySvg.selectAll('.backgroundDot').attr("r", 3);
          mySvg.selectAll('.linePath').attr("stroke-width", 3);
          mySvg.select('#mouseoverLine' + myClass).attr('visibility', 'hidden');
          myChart.hideTooltip('line');
        });
      // line path
      lineGroup.select('.linePath')
        .attr('id', (d: any, i: any) => "line" + i)
        .attr('d', line)
        .attr('stroke', (d: any) => lineColors[d[0].source])
        .attr('stroke-width', 3)
        .attr('fill','none')
        .attr('transform', 'translate(' + (margins.left) + ',' + margins.top + ')');

      lineGroup.select('.lineDotGroup')
        .attr('transform', 'translate(' + (margins.left) + ',' + margins.top + ')');

      // dot group
      const lineDotGroup = lineGroup.select('.lineDotGroup')
        .selectAll('.lineDotsGroup' + myClass)
        .data((d: any) => d)
        .join(function(group: any): any {
          const enter = group.append('g').attr('class', 'lineDotsGroup' + myClass);
          enter.append('circle').attr('class', 'backgroundDot');
          enter.append('circle').attr('class', 'lineDot');
          return enter;
        });

      // background dot so that line not visible when opacity set.
      lineDotGroup.select('.backgroundDot')
        .attr('id', (d: any, i: any) => "dot" + i)
        .attr('cx', (d: any) => xScale(d.date))
        .attr('cy', (d: any) => yScale(d.value))
        .attr('stroke-width', 0)
        .attr('r',3)
        .attr('fill','white')

      lineDotGroup.select('.lineDot')
        .attr('id', (d: any, i: any) => "dot" + i)
        .attr('cx', (d: any) => xScale(d.date))
        .attr('cy', (d: any) => yScale(d.value))
        .attr('stroke', (d: any) => lineColors[d.source])
        .attr('stroke-width', 2)
        .attr('fill-opacity', 0.3)
        .attr('r',3)
        .attr('fill',(d: any) => lineColors[d.source]);
    }


    const slider = mySvg.select('#sliderGroup' + myClass);
   
         // Bar Slider
         if(myChart.props.leftSlider){
         myChart.barMin = myChart.currentMin;
         myChart.barMax = barMax;
         createRangeSlider('bar', myChart.currentMin, barMax);
         }
         
         // Line Slider
         if(myChart.props.rightSlider){
         myChart.lineMin = myChart.currentMin;
         myChart.lineMax = yMax;
         createRangeSlider('line', myChart.currentMin, yMax);
         }
   
   
         function createRangeSlider(id: string, min: number, max: number): void {
           const sliderGroupId = `sliderGroup-${id}`;
           const sliderClass = `${id}-slider`;
         
           // Remove any existing slider group for this ID
           slider.select(`#${sliderGroupId}`).remove();
         
           // Create a new slider group
           const sliderGroup = slider.append('g').attr('id', sliderGroupId);
           // Scale setup for the slider
           const scale = d3.scaleLinear()
             .domain([min, max])
             .range(myChart.orientation === 'horizontal' ? [0, yScale.range()[0]] : [yScale.range()[0], 0])
             .clamp(true);
         
           // Drag event handlers for the min and max handles
           const dragMinHandle = d3.drag().on('drag', (event) => onDragMinHandle( sliderGroup,event, scale, sliderClass, id, min, max));
           const dragMaxHandle = d3.drag().on('drag', (event) => onDragMaxHandle( sliderGroup,event, scale, sliderClass, id, min, max));
         
           // Create the slider track and inset line
           sliderGroup.append('line')
             .attr('class', `${sliderClass} ${id}-track`)
             .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
             .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max))
             .attr('transform', `translate(${id === 'bar' ? (width - margins.left + 40) : (margins.left - 40)}, ${margins.top})`)
             .attr('stroke', '#ddd')
             .attr('stroke-width', 1);
         
           sliderGroup.append('line')
             .attr('class', `${sliderClass} ${id}-track-inset`)
             .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
             .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max + margins.top))
             .attr('transform', `translate(${id === 'bar' ? (width - margins.left + 40) : (margins.left - 40)}, ${margins.top})`)
             .attr('stroke', '#ddd')
             .attr('stroke-width', 1);
         
           // Tooltip setup
           const tooltip = d3.select('body').append('div')
             .attr('class', 'tooltip')
             .style('position', 'absolute')
             .style('padding', '5px')
             .style('background', '#fff')
             .style('border', '1px solid #ccc')
             .style('border-radius', '4px')
             .style('pointer-events', 'none')
             .style('opacity', 0);
         
           // Create Min and Max handles with hover and drag functionality
           createSliderHandle( sliderGroup,sliderClass, id, 'min', min, scale, dragMinHandle, tooltip);
           createSliderHandle(sliderGroup,sliderClass, id, 'max', max, scale, dragMaxHandle, tooltip);
         }
         
   
   
         function createSliderHandle(sliderGroup:any,sliderClass: string, id: string, handleType: string, value: number, scale: any, dragHandle: any, tooltip: any): void {
           const handle = sliderGroup.append('circle')
             .attr('class', `${sliderClass} ${id}-${handleType}-handle`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', scale(value))
             .attr('transform', `translate(${id === 'bar' ? (width - margins.left + 40) : (margins.left - 40)}, ${margins.top})`)
             .attr('r', myChart.handleRadius)
             .attr('fill', '#69b3a2')
             .call(dragHandle);
         
           // Hover effect layer
           const hoverLayer = sliderGroup.append('circle')
             .attr('class', `${sliderClass} ${id}-${handleType}-hover-layer`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', scale(value))
             .attr('transform', `translate(${id === 'bar' ? (width - margins.left + 40) : (margins.left - 40)}, ${margins.top})`)
             .attr('r', myChart.handleRadius + 8)
             .attr('fill', '#69b3a2')
             .attr('opacity', 0)
             .attr('pointer-events', 'none');
         
           // Handle hover effect
           handle.on('mouseover', function(event: any) {
             hoverLayer.transition().duration(200).attr('opacity', 0.4);  // Show hover layer
             // sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
             //         .attr('stroke-width', 4)
             //         .attr('stroke', '#69b3a2');  // Change color on hover
               //  tooltip
               // .style('opacity', 1)
               // .html(`Max: ${id === 'bar' ? myChart.barMax : myChart.lineMax}`)
               // .style('left', `${event.pageX + 10}px`)
               // .style('top', `${event.pageY - 20}px`);
           })
           .on('mouseout', function() {
             hoverLayer.transition().duration(200).attr('opacity', 0);  // Hide hover layer
             // sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
             //         .attr('stroke-width', 1)
             //         .attr('stroke', '#ddd');  // Revert to original color
                     // tooltip.style('opacity', 0);  // Hide tooltip on mouse out
           })
           .on('mousemove', function(event: any) {
             // Update tooltip position
             //  tooltip
             //   .style('opacity', 1)
             //   .html(`Max: ${id === 'bar' ? myChart.barMax : myChart.lineMax}`)
             //   .style('left', `${event.pageX + 10}px`)
             //   .style('top', `${event.pageY - 20}px`);
             // tooltip.style('left', `${event.pageX + 10}px`).style('top', `${event.pageY - 20}px`);
           });
         }
         
         function onDragMinHandle(sliderGroup:any,event: any, scale: any, sliderClass: string, id: string, min: number, max: number): void {
           const newPos = Math.max(scale(max), event[myChart.orientation === 'horizontal' ? 'x' : 'y'] - margins.top);
           const currentMin = Math.round(scale.invert(newPos) - 1);
           if (currentMin >= min && currentMin <= (id === 'bar' ? myChart.barMax - 5 : myChart.lineMax - 5)) {
             if (id === 'bar') {
               myChart.barMin = currentMin;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'min', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, myChart.barMin, max);
               drawChart();
             } else if (id === 'line') {
               myChart.lineMin = currentMin;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'min', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, myChart.lineMin, max);
               drawChart();
             }
           }
         }
         
         function onDragMaxHandle(sliderGroup:any,event: any, scale: any, sliderClass: string, id: string, min: number, max: number): void {
           const newPos = Math.min(scale(min), event[myChart.orientation === 'horizontal' ? 'x' : 'y'] - margins.top);
           const currentMax = Math.round(scale.invert(newPos) + 1);
           if (currentMax <= max && currentMax >= (id === 'bar' ? myChart.barMin + 5 : myChart.lineMin + 5)) {
             if (id === 'bar') {
               myChart.barMax = currentMax;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'max', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, min, myChart.barMax);
               drawChart();
             } else if (id === 'line') {
               myChart.lineMax = currentMax;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'max', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, min, myChart.lineMax);
               drawChart();
             }
           }
         }
         function updateTrackInset(sliderGroup:any,scale: any, sliderClass: string, id: string, min: number, max: number): void {
           sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
             .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
             .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max));
         }
         
         function updateSliderHandlePosition( sliderGroup:any,sliderClass: string, id: string, handleType: string, newPos: number): void {
           sliderGroup.select(`.${sliderClass}.${id}-${handleType}-handle`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', newPos);
             sliderGroup.select(`.${sliderClass}.${id}-${handleType}-hover-layer`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', newPos);
         }
           
    function drawLegend(currentLegendItems: any): void {
      // draw legend, similar in all cases, see area line for full explanation
      const legendGroup = mySvg
        .select('#legendGroup' + myClass)
        .selectAll('.legendGroup' + myClass)
        .data(currentLegendItems)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel' + myClass);
          return enter;
        });

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d.replace(/ /g, '') + myClass)
        .attr('cy', 35)
        .attr('fill', (d: any) => lineColors[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel' + myClass)
        .attr('id', (d: any) => 'legendItem' + d.replace(/ /g, '') + myClass)
        .attr('y', 35 + 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .attr('cursor',  'pointer')
        .text((d: any) =>d=='shipments'?'Shipments':d.replace(/_/g, ' '));

      let legendX = 30;
      mySvg.selectAll('.legendLabel' + myClass).each(function(d: any): any {
        //@ts-ignore
        const myObject = this;
        d3.select(myObject).attr('x', legendX-6);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX - 8 - 6);
        legendX += (40 + measureWidth(d, 12));
      });

      legendGroup.attr('transform', 'translate(' + ((width - legendX + 24) / 2) + ',0)');
    }

    function brushed(event: any): void {

      const selection = event.selection;
      if (selection !== null) {
        myChart.hideTooltip('line');
        // same in all sets
        // reset xDomain
        const xDomain: any = selection.map(xScaleAll.invert);
        const dataRemaining: any = dateSet.filter((f: any) => f >= xDomain[0] && f <= xDomain[1]).length;
        if(dataRemaining < 2 && dataRemaining !== dateSet.length) {
          //if only one data point, reset to previous (also catch in there in case only one data point!)
          const previousStart = xScaleAll(xScale.domain()[0]);
          const previousEnd = xScaleAll(xScale.domain()[1]);

          mySvg.select('#brushGroup' + myClass)
            //@ts-ignore
            .call(brush.move, [previousStart, previousEnd]);
        } else {
          // otherwise
          // reset x scale
          xScale.domain(xDomain);
          // call x axis again
          mySvg.select('#xAxis' + myClass)
            .call(d3.axisBottom(xScale).tickSizeOuter(0)
              .tickFormat(xTickFormat)
              .ticks(tickCount)
              .tickValues(tickValues === null ? null : tickValues.filter((f: any) => f >= xDomain[0] && f <= xDomain[1])))
            .attr('transform', 'translate(' + (margins.left ) + ',' + (height - margins.bottom - margins.brush) + ')');
          // draw chart
          drawChart();
          // show/hide partial period depending on whether last data point shown
          if(String(xDomain[1]) === String(xScaleAll.domain()[1])){
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
          } else {
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility",  "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility","hidden")
          }
          // position handle lines and reset handle styles - see area line/bar for full explanation
          const handleEastX = +mySvg.select('#brushGroup' + myClass).select('.handle--e').attr('x');
          const handleWestX = +mySvg.select('#brushGroup' + myClass).select('.handle--w').attr('x');

          mySvg.select('.handleLeftLine1' + myClass)
            .attr('x1', handleEastX + 3)
            .attr('x2', handleEastX + 3);

          mySvg.select('.handleLeftLine2' + myClass)
            .attr('x1', handleEastX + 7)
            .attr('x2', handleEastX + 7);

          mySvg.select('.handleRightLine1' + myClass)
            .attr('x1', handleWestX + 3)
            .attr('x2', handleWestX + 3);

          mySvg.select('.handleRightLine2' + myClass)
            .attr('x1', handleWestX + 7)
            .attr('x2', handleWestX + 7);

          mySvg.select('#brushGroup' + myClass)
            .selectAll('.handle')
            .attr('visibility', lineDataset.length === 0 && barDataset.length === 0 ? 'hidden' : 'visible')
            .attr('fill', 'white')
            .attr('rx', 4)
            .attr('ry', 4)
            .attr('y', ((margins.brush - margins.mid) - 24) / 2)
            .attr('height', 24)
            .style('filter', 'url(#drop-shadow)');
        }
      }
    }

    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();

      text.each(function(): any {
        let lineNumber = 0;
        //@ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 8;
        const dy = 22;
        let tspan: any = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'px');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            if(line.length > 0){
              tspan.text(line.join(' '));
              line = [word];
              if (word.trim().length > 0){
                lineNumber += 1;
                tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', lineNumber * lineHeight + dy + 'px').text(word);

              }
            } else {
              line = [word]
            }
          }
        }
      });
    }
    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }
    this.stop(10).then(() => this.isLoading = false);
  }
// property & data for the chart
  getComboBarData() {
    this.renderLinePopup = false
    this.ComboBarData = this.data
    if(this.data!= undefined) {
    this.props = {
      lineDataType: this.item?.config?.chart_type?.value, //   || "group" || "single",
      colors:["#8C23E6", "#E223E6", "#FFCD4A", "#645DD7", "gold", "gray", "purple", "orange","#E74028", "#2CB239", "#25D3EB", "#FFB039", "#1566DE", "#7848FF", "#7AD045", "#CB04DC", "#FA195C", "#9E0000", "#EACD37", "#DD630B", "#BCD03F", "#14BDA9", "#8A05F3", "#D9476A", "#2DADB6", "#E468C2", "#199CD4", "#0934CC"],
      chartHeight: 500,
      lineGroupVar: this.config.group_value_column, //not needed if lineDataType === "single"
      lineVar: this.config.value_column.toLowerCase(),//   this.item?.config?.chart_type?.value == "single" ? 'Shipments' : "Shipped_Patients",
      barVar: this.showBy == "tablets" ? this.config.yAxisVar.toLowerCase() : this.config.secondary_value_column.toLowerCase(),//"no_of_bottles", //'Shipped_Quantity'
      dateVar: this.config.xAxisVar, //"period",
      showBrush: true,
      d3AxisFormatting: false,
      period: this.filterService.report_type || 'D',
      yAxisLabelLeft: this.item.config["value_column"]["api_resp_column_display_nm"], //this.item?.config?.chart_type?.value == "single" ? 'Shipments' : "Shipped patients",
      yAxisLabelRight: this.config.yAxisLabel, //"Shipped Quantity",
      yAxisFormat: "-2~s",
      axisFontSize: 12,
      axisFontWeight: 700,
      partialPeriod: true,
      leftSlider: this.item.config?.['zoom_slider_left']?.value=='true' || false,
      rightSlider: this.item.config?.['zoom_slider_right']?.value=='true' || false
    }
    if (this.isFullscreen) {
      this.props.chartHeight = window.outerHeight - 60
    }
    if (this.ComboBarData?.lineData?.length > 0) {
      if (Object.keys(this.ComboBarData?.lineData[0]).indexOf("partial_period_flg") > -1) {
        if (this.ComboBarData?.lineData.find((f: any) => f["partial_period_flg"] === "Y") !== undefined) {
          this.props.partialPeriod = true;
        }
      }
    }

    if (this.ComboBarData?.length == 0 || (Object.keys(this.ComboBarData).length )==1) {
      this.noData =true
      this.ComboBarData = { lineData: [], barData: [] }
      this.plotChart();
    }
        if (this.ComboBarData?.lineData?.length > 0) {
          this.noData =false
          setTimeout(() => {
            this.plotChart();
          }, 50);
          
        }

      }
  }

  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
  }
  removeUnderScore(value: string) {
    return value.replace(/_/g, ' ')
  }
}



