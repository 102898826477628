<div class="widget-container" #heatMapNEW>

  <main>
    <article #fs fullscreen-able [class.is-active]="isFullscreen" style="background-color: white;">
      <div class="header">
        <div>
          <icon-chart 
            [headerConfig]="item.config.widget_header_config"
            [rowDataLength]="data"
            [data]="data"
            [chartName]="'DataGrid'" 
            [pageKey]="pageKey"
            [config]="heatMapNEW" 
            [item]="item" 
            [pageKey]="pageKey"
            [expand]="fs" 
            [fullscreen]="isFullscreen"
            (expendCol)="toggleAll($event)"
            (screenChanges)="toggleFullscreen($event)"></icon-chart>
        </div>

        <app-progress-indefinite *ngIf="isLoading "></app-progress-indefinite>

        <div class="responsive-container" *ngIf="!isLoading && data?.length" [style.gap]="staticLegend ? '25px' : '0px'">
          
          <duc-legend *ngIf="!staticLegend" [config]="config.legend"></duc-legend>
        
          <div class="flex-container" *ngIf="staticLegend">
            <span class="mr-1">Status:</span>
            <duc-legend [config]="config.legend"></duc-legend>
          </div>
        
          <div class="flex-container" *ngIf="staticLegend">
            <span class="mr-1">Indicator:</span>
            <div class="d-flex align-items-center justify-content-between indicator-container">
              <div class="groupof-static-legend" *ngFor="let data of staticLegendItems; let idx = index;">
                <img class="groupof-static-legend-img mr-2" [src]="data.url" [alt]="data.name">
                <div class="groupof-static-text">
                  <span class="label" style="text-transform: uppercase;">{{data.name}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isLoading && !data?.length" class="no-data">
          <span class="bg-text">There is no data for this time period</span>
        </div>

        <div *ngIf="!isLoading && data?.length" [ngClass]="this.item.config['tool_tip_type'].value"
          [ngStyle]="{'height': isFullscreen ? (window.innerHeight - 130) + 'px' : 'auto','overflow':isFullscreen ?'auto':'none','margin': '10px 0'}"
          style="overflow-y: auto;"
          >

          <!-- [ngStyle]="{'height': isFullscreen ? window.innerHeight + 'px' : 'auto','overflow':'auto'}" -->

          <div class="tree-table">
            <duc-tree [config]="config.tree"></duc-tree>
            <duc-status-overview [config]="config.statusOverview" [data]="data" (cellSelected)="toggleModal($event)">
              <!-- TODO use ngComponentOutlet in Angular 16+-->
              <app-status-tooltip-dem #tooltip *ngIf="this.item.config['tool_tip_type'].value === 'dem'"
                [config]="config.tooltip"></app-status-tooltip-dem>
              <app-status-tooltip-dpm #tooltip *ngIf="this.item.config['tool_tip_type'].value === 'dpm'"
                [config]="config.tooltip"></app-status-tooltip-dpm>
            </duc-status-overview>
          </div>

        </div>
      </div>

    </article>
  </main>
</div>