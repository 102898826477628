import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from 'd3';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as htmlToImage from 'html-to-image';
import { FilterService } from 'src/app/services/filter.service';
import moment from 'moment';
import { CurrencyPipe } from '@angular/common';
import { NewFilterService } from 'src/app/services/new-filter.service';

@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})

export class BarChartComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('BarChartContainer', { static: true }) BarChartContainer!: ElementRef;
  @ViewChild('customTemplate') customTemplate: any;
  @Input('data') data: any
  @Input('item') item: any
  @Input('pageKey') pageKey: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  iconList: any = []
  @ViewChild('fs') fs!: ElementRef;
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  isActive = false;
  barData: any;
  props: any = {}
  currentVisibleData: any = [];
  divId: any = 'barChart';
  setting = "setting-sm"
  mytooltipData: any = '';
  tooltipVisible: boolean = false;
  initiateChart: Boolean = false
  dataTurn: number = 0;
  counter = 2;
  plus = "plus"
  minus = "frame 4"
  popoverTitle = 'Popover title';
  popoverMessage = 'Popover description';
  confirmClicked = false;
  cancelClicked = false;
  showBy: any
  noData:Boolean=false

  
//sider Variables
private sliderSize = 300;
private handleRadius = 6;
private currentMin = 0;
private currentMax = 100;
private margins = {left:10,top:20}
private orientation = 'vertical';

  constructor(private filterService: FilterService, private newFilterService: NewFilterService, private currency: CurrencyPipe) {
    this.newFilterService.showBy.subscribe((value: any) => {
      this.showBy = value
      this.getBarChartData()

    })

    this.filterService.movingAverage.subscribe((res:any)=> {
      this.counter = res
      this.countSave(res)
    })
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }


  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      this.isFullscreen = false
    }
  }

//loade Method
isLoading = false;
  async stop(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }
  start() {
    this.isLoading = true;
  }
  increment() {
    if (this.counter >= 9) {
      alert('Moving Average Limit  ( 0 - 9)')
      return
    } else {
      this.counter++;
    }
  }
  decrement() {
    if (this.counter < 1) {
      return
    } else {
      this.counter--;
    }
  }
  countSave(ctrl: any) {
    this.getBarChartData();
    this.BarChartContainer?.nativeElement.click();
  }

  cancel() {
    this.BarChartContainer?.nativeElement.click();
  }
  onChangeValue(event: any) {
    this.counter = event;
  }
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any): void {
    this.dataTurn = 0
    this.dataTurn = chartWidth - myX
    this.mytooltipData = myData

    if (this.dataTurn < 250) {
      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 'px')
        .style('right', (this.dataTurn + 20) + 'px')
        .style('left', 'unset')
    }
    else if (this.dataTurn > 250) {

      d3.select("#d3BarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('top', myY + 'px')
        .style('left', (myX + 20) + 'px')
        .style('right', 'unset')
    }
    // if (this.isFullscreen == true) {
    //   d3.select("#d3BarTooltip")
    //     .style('top', myY + 27 + 'px')
    // } else { }

    this.tooltipVisible = true
  }

  private hideTooltip(myType: any): void {
    this.tooltipVisible = false
    // d3.select("#d3BarTooltip")
      // .style('visibility', 'hidden');
  }
  ngOnDestroy(): void {
    this.data = undefined
    this.barData = undefined
  }

  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.initiateCharts();
    window.addEventListener('orientationchange', (event: any) => {
      this.getBarChartData()
      if (window.orientation === 0 || window.orientation === 180) {
        // Portrait orientation
        // Call your specific portrait mode function or perform actions
      } else if (window.orientation === 90 || window.orientation === -90) {
        // Landscape orientation
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getBarChartData();
    }
  }


  openPDF(imgData: any) {
    // Generate PDF
    var doc = new jsPDF('p', 'pt', 'a4');
    doc.text(this.heading, 225, 30);
    doc.addImage(imgData, 'PNG', 0, 50, 600, 500);
    doc.save(this.heading + '.pdf');
  }

  onSaveChartClicked(): void {
    const theElement = this.BarChartContainer.nativeElement
    htmlToImage.toPng(theElement).then((dataUrl) => {
      this.openPDF(dataUrl);
    });
  }

  openFullscreen(): void {
    this.isFullscreen = true;
    const el = this.fs.nativeElement;
    if (!document.fullscreenElement   // alternative standard method
    ) {  // current working methods
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if (el.mozRequestFullScreen) {
        el.mozRequestFullScreen();
      }
    }
    setTimeout(() => {
      this.isActive = true;
      this.plotChart()
    }, 500);



  }

  closeFullscreen(): void {
    this.props.chartHeight = 500
    this.isFullscreen = false;
    this.isActive = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }
// property & data for the chart
  getBarChartData(): void {
    this.barData = this.data;
    if (this.barData != undefined && this.config) {
      this.props = {
        "colours": { "quantityShipped": '#1363DF', "movingAverage": '#FFD951' },
        "period": this.filterService.report_type || "D",
        "xAxisLabel": this.config?.xAxisLabel,
        "yAxisLabel": this.config?.yAxisLabel,
        "xAxisVar": this.config?.xAxisVar,
        "yAxisVar": this.showBy == "tablets" ? this.config?.yAxisVar : "number_of_bottles",
        "showBrush": true,
        "d3AxisFormatting": false,
        "yAxisFormatting": "-2~s",
        "axisFontSize": 12,
        "axisFontWeight": 700,
        "movingAveragePeriod": this.counter || 1,
        "chartHeight": 500,
        "partialPeriod": true,
        "slider": this.item.config?.['zoom_slider']?.value=='true' || false

      }
      if (this.isFullscreen) {
        this.props.chartHeight = window.outerHeight - 60
      }
      if (this.barData !== undefined && this.barData.length > 0) {
        if (Object.keys(this.barData[0]).indexOf("partial_period_flg") > -1) {
          if (this.barData.find((f: any) => f["partial_period_flg"] === "Y") !== undefined) {
            this.props.partialPeriod = true;
          }
        }
        this.noData =false
        setTimeout(() => {
          this.plotChart();
        }, 50);
      } else {
        this.noData=true
        this.barData = []
        this.plotChart();
      }
    }
  }
// property & data for the chart
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select('#' + myClass)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');

    mySvg.append('text').attr('id', 'noDataMessage' + myClass);
    const defs = mySvg.append('defs');

    defs.append('clipPath').attr('id', 'brushClip' + myClass)
      .append('rect').attr('id', 'brushClipRect' + myClass);

      defs.append('clipPath').attr('id', 'alcClip' + myClass)
      .append('rect').attr('id', 'alcClipRect' + myClass);

    const filter = defs.append('filter').attr('id', 'drop-shadow').attr('width', 10).attr('height', 24);
    filter.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', 1).attr('result', 'blur');
    filter.append('feOffset').attr('in', 'blur').attr('dx', 1)
      .attr('dy', 1).attr('result', 'offsetBlur');
    filter.append('feFlood').attr('in', 'offsetBlur')
      .attr('flood-color', '#000000').attr('flood-opacity', 0.4)
      .attr('result', 'offsetColor');
    filter.append('feComposite').attr('in', 'offsetColor').attr('in2', 'offsetBlur').attr('operator', 'in').attr('result', 'offsetBlur');
    const feMerge = filter.append('feMerge');
    feMerge.append('feMergeNode').attr('in', 'offsetBlur');
    feMerge.append('feMergeNode').attr('in', 'SourceGraphic');
    mySvg.append('g').attr('id', 'xAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('g').attr('id', 'yAxis' + myClass).attr('class', 'axis' + myClass);
    mySvg.append('line').attr('class', 'partialPeriodLine' + myClass);
    mySvg.append('text').attr('class', 'partialPeriodLineLabel' + myClass);
    mySvg.append('text').attr('id', 'xAxisLabel' + myClass);
    mySvg.append('text').attr('id', 'yAxisLabel' + myClass);
    mySvg.append('g').attr('id', 'chartGroup' + myClass);
    mySvg.append('g').attr('id', 'brushGroup' + myClass);
    mySvg.append('g').attr('id', 'brushSelectionGroup' + myClass);
    mySvg.append('g').attr('id', 'sliderGroup' + myClass).attr('class', 'slider' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleLeftLine2' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine1' + myClass);
    mySvg.append('line').attr('class', 'handleLines' + myClass + ' handleRightLine2' + myClass);
    mySvg.append('circle').attr('class', 'legendCircleBar' + myClass + ' legendItem' + myClass);
    mySvg.append('circle').attr('class', 'legendCircleLine' + myClass + ' legendItem' + myClass);
    mySvg.append('text').attr('class', 'legendLabelLine' + myClass + ' legendItem' + myClass);
    mySvg.append('text').attr('class', 'legendLabelBar' + myClass + ' legendItem' + myClass);
    this.initiateChart = true
  }

// chart svg  plotChart rendering 
  plotChart(): void {
    const myChart:any = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = this.props.chartHeight;
    const circleRadius = 4;
    // set height
    mySvg.attr("height", height);
    let margins: any = { left: 75, right: 40, top: 90, bottom: 10, brush: 52, mid: 80 };
    if(myChart.props.showBrush === false){
      // less if no brush
      margins.brush = 0;
      margins.mid = 50;
      margins.bottom = 0;
    }
    
      // Bar Slider
      myChart.barMin = undefined;
      myChart.barMax = undefined;
      // Line Slider
      myChart.lineMin = undefined;
      myChart.lineMax = undefined;

    const chartHeight = height - margins.top - margins.bottom - margins.brush - margins.mid;
    const timeConverter: any = {"D": d3.timeDay, "W": d3.timeWeek, "M": d3.timeMonth, "Q": d3.timeMonth, "Y": d3.timeYear}
    // tickFormat is standard across 4 charts (area line, bar, line, combo)
    // could be added to properties or stored elsewhere
    const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y"};
    // period defined in properties
    const timePeriod = this.props.period;
    // format then defined from tickFormat
    const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);
    let chartData: any =  this.barData;
    // convert string to date
    chartData.map((m: any) => m.date = new Date(m[myChart.props.xAxisVar]));
    // build date group and sort ascending
    let dateGroup = Array.from(d3.group(chartData, (g: any) => g.date));
    dateGroup = dateGroup.sort((a: any, b: any) => d3.ascending(a[0], b[0]));
    const dateGroupDates: any = [];
    chartData = [];
    let monthlyTotals: any = [];
    // loop through date group
    dateGroup.forEach((d: any, i: any)  => {
      // build the set
      dateGroupDates.push(d[0]);
      let myAverage: any = null;
      let mySlice = []
      // calculate and build monthly totals
      const monthlyTotal = d3.sum(d[1], (s: any) => s[myChart.props.yAxisVar]);
      monthlyTotals.push(monthlyTotal);
      // if there are more data elements PREVIOUS than moving average period
      // create the slice of the last x months for moving average calculation
      // for more info on moving average calculation ask Sitaram/Angular team
      if(monthlyTotals.length >= (myChart.props.movingAveragePeriod)){
        mySlice = monthlyTotals.slice(monthlyTotals.length - myChart.props.movingAveragePeriod, monthlyTotals.length);
      }
      // calculate the average
      myAverage = mySlice.length === 0 ? null : d3.mean(mySlice)
      chartData.push({
        date: d[0],
        quantityShipped: monthlyTotal,
        movingAverage: myAverage,
        mySlice: mySlice
      });
    });
    // if d3 axis formatting === false, set tick values, one per unique date
    const tickValues = myChart.props.d3AxisFormatting === true ? null : dateGroupDates;
    // if d3 axis formatting === true, recommend 6 ticks
    const tickCount = myChart.props.d3AxisFormatting === true ? 6 : null;
    // calculate max and extent
    const yMax:any = d3.max(chartData, (m: any) => Math.max(m.quantityShipped, m.movingAverage));
    let xExtent: any = d3.extent(chartData, (d: any) => d.date);
    const xMax = d3.max(chartData, (d: any) => d.date);
    //offset data to leave a gap left + right (as per design)
    // timeConverter decides what length of gap to have dependent on the time period
    // so for example, if timePeriod is day it is 1 day and so on
    xExtent[0] = timeConverter[timePeriod].offset(xExtent[0], -1);
    xExtent[1] = timeConverter[timePeriod].offset(xExtent[1], 1);
    // with a minimum bar with of around 25, calculate how many dates will fit
    let startingVisibleRows: any = parseInt(String((width - margins.left - margins.right) / 25));
    // check in case this is more than the data length
    startingVisibleRows = startingVisibleRows > chartData.length ? chartData.length : startingVisibleRows;
    // if there are more data points than startingVisibleRows, set brushStartX to zoom in initially
    const brushStartX = chartData.length === 0 ? 0 : (1-(startingVisibleRows/chartData.length)) * (width - margins.left - margins.right);
    // set brush
    const brush: any = d3.brushX()
        .handleSize(10)
        .extent([[0, 0], [width - margins.left - margins.right, margins.brush]])
      //@ts-ignore
      .on('start brush end',!myChart.props.showBrush ? null : brushed);
    // will become redundant when no data component in place
    mySvg.select('#noDataMessage' + myClass)
      .attr("x", margins.left + (width - margins.left - margins.right)/2)
      .attr("y", margins.top + (height - margins.top - margins.mid - margins.brush - margins.bottom)/2)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '16px')
      .attr('font-weight', '500')
      .attr('text-anchor', 'middle')
      .text(chartData.length === 0 ? "There is no data for this time period" : "");
    //static legend (only ever 2 variables)
    const legendMid = (margins.top/2) - 20;

    mySvg.select('.legendCircleBar' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('cx', circleRadius)
      .attr('cy', legendMid)
      .attr('r', circleRadius)
      .attr('fill',myChart.props.colours.quantityShipped);

    mySvg.select('.legendLabelBar' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('x', circleRadius * 3)
      .attr('y', legendMid + 5)
      .attr('r', circleRadius)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '12px')
      .attr('font-weight', '500')
      .text('Quantity Shipped');
    // measure the legend width so you know where to place next elements
    const quantityLegendWidth = (circleRadius * 3) + 15 + measureWidth('Quantity Shipped', 14);

    mySvg.select('.legendCircleLine' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('cx', circleRadius  + quantityLegendWidth)
      .attr('cy', legendMid)
      .attr('r', circleRadius)
      .attr('fill',myChart.props.colours.movingAverage);

    mySvg.select('.legendLabelLine' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('x', (circleRadius * 3) + quantityLegendWidth)
      .attr('y', legendMid + 5)
      .attr('r', circleRadius)
      .attr('font-family', 'Poppins')
      .attr('fill', '#101D42')
      .attr('font-size', '12px')
      .attr('font-weight', '500')
      .text('Moving Average');
    // measure the average legend width so you can centre the legend accurately
    const legendLabelWidth = (circleRadius * 3) + 15 + measureWidth('Moving Average', 14);
    // centre the legend
    mySvg.selectAll('.legendItem' + myClass)
      .attr('transform', 'translate(' + ((width - legendLabelWidth - quantityLegendWidth)/2) + ',0)');
    // set brush overlay fill to transparent
    mySvg.select('.overlay')
        .style('fill', 'transparent');
        
        //brush clip rect dimensions
        mySvg.select('#alcClipRect' + myClass)
        .style('width', width -  margins.right - margins.left)
        .style('height',  margins.brush)
        .attr('transform', 'translate(' + margins.left + ',' + (chartHeight + margins.top + margins.mid ) + ')');
    
    // define clip rect dimensions
    mySvg.select('#brushClipRect' + myClass)
        .style('width', width - margins.left - margins.right)
        .style('height', chartHeight  )
        .attr('transform', 'translate(' + margins.left + ','+ margins.top +')');
    // set x and y scales
    const yScale = d3.scaleLinear().domain([0, Number(yMax)]).range([Number(height - margins.top - margins.bottom - margins.mid - margins.brush), 0]);
    // brush has smaller y scale
    const yScaleBrush = d3.scaleLinear().domain([0, Number(yMax)]).range([margins.brush, 0]);
    // x scale all for the brush and as a base, selected is current bar scale
    const xScaleAll: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    const xScale: any = d3.scaleTime().range([0, width - margins.left - margins.right]).domain(xExtent);
    // x and y axis labels
    mySvg.select('#xAxisLabel' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('x', margins.left + ((width - margins.left - margins.right)/2))
      .attr('y', height - margins.bottom - margins.brush - 10)
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.xAxisLabel);

    mySvg.select('#yAxisLabel' + myClass)
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .attr('transform','translate(13,' + (margins.top + ((height - margins.top - margins.bottom - margins.brush - margins.mid)/2)) + ') rotate(-90)')
      .attr('text-anchor', 'middle')
      .attr('font-family', 'Poppins')
      .attr('fill', '#737D88')
      .attr('font-size', '12px')
      // @ts-ignore
      .text(myChart.props.yAxisLabel);
    // x and y axis
    mySvg.select('#xAxis' + myClass)
        .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(tickValues).ticks(tickCount).tickFormat(xTickFormat))
        .attr('transform', 'translate(' + margins.left + ',' + (margins.top + chartHeight) + ')');

    mySvg.select('#yAxis' + myClass)
        .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5).tickFormat((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormatting)(d)))
        .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');
    //axis formatting
    mySvg.selectAll('.axis' + myClass + ' path')
      .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible')
      .style('stroke', '#E8EAEE');
    // set data depending on whether brush is needed
    const chartGroupData = myChart.props.showBrush === false ? ['chart']: ['brush', 'chart'];
    //brush and chart line definitions
    const lineBrush = d3.line()
        // .curve(d3.curveCardinal)
        .defined((d: any) => d.movingAverage !== null)
        .x((d: any) => xScaleAll(d.date))
        .y((d: any) => yScaleBrush(d.movingAverage));

    const lineFocus = d3.line()
        // .curve(d3.curveCardinal)
        .defined((d: any) => d.movingAverage !== null)
        .x((d: any) => xScale(d.date))
        .y((d: any) => yScale(d.movingAverage));
    // call brush and move to brushStartX (0 if dataset is small enough to show all)
    mySvg.select('#brushGroup' + myClass)
        .attr('transform', 'translate(' + margins.left + ',' + (margins.top + chartHeight + margins.mid) + ')')
        .call(brush)
        .call(brush.move, [brushStartX, width - margins.left - margins.right]);
    // hid brush is set to not show
    if(myChart.props.showBrush === false){
      mySvg.select('#brushGroup' + myClass)
        .call(brush.move,null)
        .selectAll("*").remove();
    }
    // setting basic property of handle lines (x position changes with brush)
    mySvg.selectAll('.handleLines' + myClass)
      .attr('visibility', chartData.length === 0 || myChart.props.showBrush === false? 'hidden' : 'visible')
      .attr('y1', margins.top + chartHeight + margins.mid + (margins.brush - 12) / 2)
        .attr('y2', margins.top + chartHeight + margins.mid + 12 + (margins.brush - 12) / 2)
        .style('stroke', '#8A98AB')
        .style('stroke-width', '1')
        .attr('transform', 'translate(' + margins.left + ',0)');
    // brush selection styling
    mySvg.selectAll('#brushGroup' + myClass)
        .selectAll('.selection')
        .attr('fill', '#A0A0A0')
        .attr('fill-opacity', '0.2')
        .style('stroke', '#E8EAEE')
        .style('stroke-width', '1');
    // draw chart
    drawChart();

    function drawChart(): void {

        const yScale      = d3.scaleLinear().domain([ myChart.lineMin || 0 , myChart.lineMax || Number(yMax)   ]).range([height - margins.top - margins.bottom - margins.mid - margins.brush, 0]);
        const yScaleAll   = d3.scaleLinear().domain([ myChart.lineMin || 0 , myChart.lineMax || Number(yMax)   ]).range([margins.brush,0]);
        const yScaleBrush = d3.scaleLinear().domain([ myChart.lineMin || 0 , myChart.lineMax || Number(yMax)   ]).range([margins.brush, 0]);

          //brush and chart line definitions
          const lineBrush = d3.line()
          // .curve(d3.curveCardinal)
          .defined((d: any) => d.movingAverage !== null)
          .x((d: any) => xScaleAll(d.date))
          .y((d: any) => yScaleBrush(d.movingAverage));

          const lineFocus = d3.line()
          // .curve(d3.curveCardinal)
          .defined((d: any) => d.movingAverage !== null)
          .x((d: any) => xScale(d.date))
          .y((d: any) => yScale(d.movingAverage));
          
    mySvg.select('#yAxis' + myClass)
    .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5).tickFormat((d: any) => d === 0 ? '': d3.format(myChart.props.yAxisFormatting)(d)))
    .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

      //x axis line and text styling (need to reset every time axis resets)
      mySvg.selectAll('#xAxis' + myClass + ' line')
        .attr('y1', '0')
        .attr('y2', -(height - margins.top - margins.bottom - margins.brush - margins.mid))
        .style('stroke', '#F0F3F6')
        .style('stroke-width', 1);

      mySvg.selectAll('.axis' + myClass + ' text')
        .style('font-weight', myChart.props.axisFontWeight)
        .style('font-family', "Poppins")
        .style('font-size', myChart.props.axisFontSize)

      mySvg.selectAll('#xAxis' + myClass + ' text')
        .style("text-anchor", "middle")
        .attr("dx", -5)
        .attr("dy", 5)
        .attr("transform", "");

      mySvg.selectAll('#yAxis' + myClass + ' line')
        .attr('x1', '0')
        .attr('x2', width - margins.right - margins.left)
        .style('stroke', '#F0F3F6')
        .style('stroke-width', 1);
      // calculate xBandwidth (using a linear scale here for brush)
      let ticksArray = tickValues === null ? xScale.ticks() : tickValues.filter((f: any) => f >= xScale.domain()[0] && f <= xScale.domain()[1]);
      ticksArray = ticksArray.sort((a: any, b: any) => d3.ascending(a,b));
      const xBandwidth: any = d3.min(ticksArray, (m: any, i: any) => i === ticksArray.length - 1
        ? width - margins.chartRight - margins.chartLeft : xScale(ticksArray[i+1]) - xScale(m) )

      const maxTextWidth = getMaxTextWidth();
      if(maxTextWidth > (xBandwidth - 5)){
        // if there is not enough space for ticks (overlap)
        // reset the font size
        let myFontSize = xBandwidth < myChart.props.axisFontSize + 2 ? xBandwidth - 2 : myChart.props.axisFontSize;
        // slightly different positioning depending on whether d3 or tick per date point
        // if tick per date point, rotate by -45
        mySvg.selectAll('#xAxis' + myClass + ' text')
          .style("text-anchor", (myChart.props.d3AxisFormatting === true ? "middle" : "end" ))
          .attr("dy",  (myChart.props.d3AxisFormatting === true ? 5 : 1 ))
          .style("font-size", myFontSize)
          .attr("transform", "rotate(" + (myChart.props.d3AxisFormatting === true ? 0 : -45 ) + ")");
      }

      function getMaxTextWidth(){
        // looping through x axis and using getBBox to get tick width and return max
        let maxTextWidth =  0;
        mySvg.selectAll('#xAxis' + myClass + ' text').each(function(){
          // @ts-ignore
          const myNode = d3.select(this).node().getBBox();
          maxTextWidth = Math.max(maxTextWidth, myNode.width)
        })
        return maxTextWidth
      }
      if(myChart.props.showBrush === true){
        // if brush, get handle x positions and reset handle line x positions
        const handleEastX = +mySvg.select('#brushGroup' + myClass).select('.handle--e').attr('x');
        const handleWestX = +mySvg.select('#brushGroup' + myClass).select('.handle--w').attr('x');

        mySvg.select('.handleLeftLine1' + myClass)
          .attr('x1', handleEastX + 3)
          .attr('x2', handleEastX + 3);

        mySvg.select('.handleLeftLine2' + myClass)
          .attr('x1', handleEastX + 7)
          .attr('x2', handleEastX + 7);

        mySvg.select('.handleRightLine1' + myClass)
          .attr('x1', handleWestX + 3)
          .attr('x2', handleWestX + 3);

        mySvg.select('.handleRightLine2' + myClass)
          .attr('x1', handleWestX + 7)
          .attr('x2', handleWestX + 7);
        // reset handle styling
        mySvg.select('#brushGroup' + myClass)
          .selectAll('.handle')
          .attr('fill', 'white')
          .attr('rx', 4)
          .attr('ry', 4)
          .attr('y', (margins.brush - 24) / 2)
          .attr('height', 24)
          .style('filter',  'url(#drop-shadow)');
      }
      // partial period line and label (reposition)
      mySvg.select('.partialPeriodLine' + myClass)
        .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
        .attr("x1", margins.left + xScale(xMax))
        .attr("x2",margins.left + xScale(xMax))
        .attr("y1", margins.top)
        .attr("y2", chartHeight + margins.top)
        .attr('stroke', '#1363DF')
        .attr('stroke-width', 1)
        .attr('stroke-dasharray','2,2');

      mySvg.select('.partialPeriodLineLabel' + myClass)
        .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
        .attr("transform", "translate(" + (margins.left + xScale(xMax)) + "," +
          (margins.top - 12) + ")")
        .attr("text-anchor", "middle")
        .attr("dy", 0)
        .attr('font-size', '8px')
        .attr('font-family', 'Poppins')
        .attr('font-weight', '400')
        .attr('fill', '#1363DF')
        .text('Partial period')
        .call(wrap, 35);
      // make sure you hide selection no data (redundant soon)
      mySvg.selectAll(".selection")
        .attr('visibility', chartData.length === 0 ? 'hidden' : 'visible');
      // set data for Angular team
      myChart.currentVisibleData = [chartData, xScale.domain()];
      // finally the bars!!!
      // chart group - this is set up in a slightly odd way
      // chartGroupData shows brush and chart
      const chartGroup = mySvg.select('#chartGroup' + myClass)
          .selectAll('.chartGroup' + myClass)
          .data(chartGroupData)
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'chartGroup' + myClass);
            enter.append('path').attr('class', 'averageLine');
            enter.append('g').attr('class', 'barsGroup');
            return enter;
          });
      // clip bath for chart
      chartGroup.attr('clip-path', (d: any) => d === 'brush' ? 'url(#alcClip' + myClass + ')' : 'url(#brushClip' + myClass + ')');
      // average line for both (line scale different)
      chartGroup.select('.averageLine')
          .style('stroke', '#FFCD4A')
          .style('fill', 'transparent')
          .style('stroke-width', 4)
          .attr('d', (d: any) => d === 'brush' ? lineBrush(chartData) : lineFocus(chartData))
          .attr('transform', (d: any) => 'translate(' + margins.left
              + ',' + (d === 'brush' ? (margins.mid + chartHeight + margins.top) : margins.top) + ')');
      // different transforms
      chartGroup.select('.barsGroup')
          .attr('transform', (d: any) => 'translate(0,' + (d === 'brush' ? (margins.mid + chartHeight) : 0) + ')');

      const barGroup = chartGroup.select('.barsGroup')
          .selectAll('.barsGroupGroup')
          .data((d: any) => {
            // may date and type to data
            const myData = JSON.parse(JSON.stringify(chartData));
            myData.map((n: any) => n.date = new Date(n.date));
            myData.map((n: any) => n.type = d);
            return myData;
          })
          .join((group: any) => {
            const enter = group.append('g').attr('class', 'barsGroupGroup');
            enter.append('rect').attr('class', 'barsGroupItems totalRect');
            enter.append('circle').attr('class', 'barsGroupItems averageDot');
            return enter;
          });
      // average dot - different scale if brush
      barGroup.select('.averageDot')
          .attr('cx', (d: any) => d.type === 'brush' ? xScaleAll(d.date) : xScale(d.date))
          .attr('cy', (d: any) => d.type === 'brush' ? yScaleBrush(d.movingAverage) : yScale(d.movingAverage))
          .attr('fill', '#FFF0C9')
          .style('stroke', '#FFCD4A')
          .attr('r', (d: any) => d.movingAverage === null ? 0 : circleRadius)
          .style('stroke-width', 2)
          .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');
      // the bar rect (again different if brush)
      barGroup.select('.totalRect')
          .attr('x', (d: any) => d.type === 'brush' ? xScaleAll(d.date) - 2 : xScale(d.date) - 2)
          .attr('y', (d: any) => d.type === 'brush' ? yScaleBrush(d.quantityShipped) : yScale(d.quantityShipped))
          .attr('width', 4)
          .attr('height', (d: any) => d.type === 'brush' ? yScaleBrush(0) - yScaleBrush(d.quantityShipped) : yScale(0) - yScale(d.quantityShipped))
          .attr('fill', myChart.props.colours.quantityShipped)
          .attr('transform',  'translate(' + margins.left + ',' + margins.top + ')');
      // mouseover
      mySvg.selectAll('.barsGroupItems')
        .on('mouseover',  (event: any, d: any) => {
          const currentIndex = dateGroupDates.findIndex((f: any) => String(f) === String(d.date));
          let comparedToPreviousQS = undefined, comparedToPreviousMA = undefined;
          // calculate whether up/down/no change
          if(currentIndex > 0){
            const previousValue = chartData[currentIndex - 1].quantityShipped;
            if(previousValue < d.quantityShipped){
              comparedToPreviousQS = 'up';
            } else if (previousValue === d.quantityShipped) {
              comparedToPreviousQS = 'no change'
            } else {
              comparedToPreviousQS = 'down';
            }
            // same for moving average
            const previousValueAverage = chartData[currentIndex - 1].movingAverage;
            if(previousValueAverage < d.movingAverage){
              comparedToPreviousMA = 'up';
            } else if (previousValueAverage === d.movingAverage) {
              comparedToPreviousMA = 'no change'
            } else {
              comparedToPreviousMA = 'down';
            }
          }
          d
          d.comparedToPreviousQS = comparedToPreviousQS;
          d.comparedToPreviousMA = comparedToPreviousMA;
          d.colours = myChart.props.colours;
          // pass data to tooltip
          myChart.showTooltip('averageDot', d, event.offsetX, event.offsetY, width);
        })
        .on('mouseout',  () => {
          // hide tooltip
          myChart.hideTooltip('averageDot');
        });
    }
      const slider = mySvg.select('#sliderGroup' + myClass);
    
        // // Bar Slider
        // myChart.barMin = myChart.currentMin;
        // myChart.barMax = sbarMax;
        // createRangeSlider('bar', myChart.currentMin, sbarMax);
        
        // Line Slider
        if(myChart.props.slider){
        myChart.lineMin = myChart.currentMin;
        myChart.lineMax = yMax;
        createRangeSlider('bar', myChart.currentMin, yMax);
      }
        
    
    
            function createRangeSlider(id: string, min: number, max: number): void {
               const sliderGroupId = `sliderGroup-${id}`;
               const sliderClass = `${id}-slider`;
             
               // Remove any existing slider group for this ID
               slider.select(`#${sliderGroupId}`).remove();
             
               // Create a new slider group
               const sliderGroup = slider.append('g').attr('id', sliderGroupId);
               // Scale setup for the slider
               const scale = d3.scaleLinear()
                 .domain([min, max])
                 .range(myChart.orientation === 'horizontal' ? [0, yScale.range()[0]] : [yScale.range()[0], 0])
                 .clamp(true);
             
               // Drag event handlers for the min and max handles
               const dragMinHandle = d3.drag().on('drag', (event) => onDragMinHandle( sliderGroup,event, scale, sliderClass, id, min, max));
               const dragMaxHandle = d3.drag().on('drag', (event) => onDragMaxHandle( sliderGroup,event, scale, sliderClass, id, min, max));
             
               // Create the slider track and inset line
               sliderGroup.append('line')
                 .attr('class', `${sliderClass} ${id}-track`)
                 .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
                 .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max))
                 .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
                 .attr('stroke', '#ddd')
                 .attr('stroke-width', 1);
             
               sliderGroup.append('line')
                 .attr('class', `${sliderClass} ${id}-track-inset`)
                 .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
                 .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max + margins.top))
                 .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
                 .attr('stroke', '#ddd')
                 .attr('stroke-width', 1);
             
               // Tooltip setup
               const tooltip = d3.select('body').append('div')
                 .attr('class', 'tooltip')
                 .style('position', 'absolute')
                 .style('padding', '5px')
                 .style('background', '#fff')
                 .style('border', '1px solid #ccc')
                 .style('border-radius', '4px')
                 .style('pointer-events', 'none')
                 .style('opacity', 0);
             
               // Create Min and Max handles with hover and drag functionality
               createSliderHandle( sliderGroup,sliderClass, id, 'min', min, scale, dragMinHandle, tooltip);
               createSliderHandle(sliderGroup,sliderClass, id, 'max', max, scale, dragMaxHandle, tooltip);
             }
             
       
       
             function createSliderHandle(sliderGroup:any,sliderClass: string, id: string, handleType: string, value: number, scale: any, dragHandle: any, tooltip: any): void {
               const handle = sliderGroup.append('circle')
                 .attr('class', `${sliderClass} ${id}-${handleType}-handle`)
                 .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', scale(value))
                 .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
                 .attr('r', myChart.handleRadius)
                 .attr('fill', '#69b3a2')
                 .call(dragHandle);
             
               // Hover effect layer
               const hoverLayer = sliderGroup.append('circle')
                 .attr('class', `${sliderClass} ${id}-${handleType}-hover-layer`)
                 .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', scale(value))
                 .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
                 .attr('r', myChart.handleRadius + 8)
                 .attr('fill', '#69b3a2')
                 .attr('opacity', 0)
                 .attr('pointer-events', 'none');
             
               // Handle hover effect
               handle.on('mouseover', function(event: any) {
                 hoverLayer.transition().duration(200).attr('opacity', 0.4);  // Show hover layer
                 // sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
                 //         .attr('stroke-width', 4)
                 //         .attr('stroke', '#69b3a2');  // Change color on hover
                   //  tooltip
                   // .style('opacity', 1)
                   // .html(`Max: ${id === 'bar' ? myChart.barMax : myChart.lineMax}`)
                   // .style('left', `${event.pageX + 10}px`)
                   // .style('top', `${event.pageY - 20}px`);
               })
               .on('mouseout', function() {
                 hoverLayer.transition().duration(200).attr('opacity', 0);  // Hide hover layer
                 // sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
                 //         .attr('stroke-width', 1)
                 //         .attr('stroke', '#ddd');  // Revert to original color
                         // tooltip.style('opacity', 0);  // Hide tooltip on mouse out
               })
               .on('mousemove', function(event: any) {
                 // Update tooltip position
                 //  tooltip
                 //   .style('opacity', 1)
                 //   .html(`Max: ${id === 'bar' ? myChart.barMax : myChart.lineMax}`)
                 //   .style('left', `${event.pageX + 10}px`)
                 //   .style('top', `${event.pageY - 20}px`);
                 // tooltip.style('left', `${event.pageX + 10}px`).style('top', `${event.pageY - 20}px`);
               });
             }
             
             function onDragMinHandle(sliderGroup:any,event: any, scale: any, sliderClass: string, id: string, min: number, max: number): void {
               const newPos = Math.max(scale(max), event[myChart.orientation === 'horizontal' ? 'x' : 'y'] - margins.top);
               const currentMin = Math.round(scale.invert(newPos) - 1);
               if (currentMin >= min && currentMin <= (id === 'line' ? myChart.barMax - 5 : myChart.lineMax - 5)) {
                 if (id === 'line') {
                   myChart.barMin = currentMin;
                   updateSliderHandlePosition(sliderGroup,sliderClass, id, 'min', newPos);
                   updateTrackInset(sliderGroup,scale, sliderClass, id, myChart.barMin, max);
                   drawChart();
                 } else if (id === 'bar') {
                   myChart.lineMin = currentMin;
                   updateSliderHandlePosition(sliderGroup,sliderClass, id, 'min', newPos);
                   updateTrackInset(sliderGroup,scale, sliderClass, id, myChart.lineMin, max);
                   drawChart();
                 }
               }
             }
             
             function onDragMaxHandle(sliderGroup:any,event: any, scale: any, sliderClass: string, id: string, min: number, max: number): void {
               const newPos = Math.min(scale(min), event[myChart.orientation === 'horizontal' ? 'x' : 'y'] - margins.top);
               const currentMax = Math.round(scale.invert(newPos) + 1);
               if (currentMax <= max && currentMax >= (id === 'line' ? myChart.barMin + 5 : myChart.lineMin + 5)) {
                 if (id === 'line') {
                   myChart.barMax = currentMax;
                   updateSliderHandlePosition(sliderGroup,sliderClass, id, 'max', newPos);
                   updateTrackInset(sliderGroup,scale, sliderClass, id, min, myChart.barMax);
                   drawChart();
                 } else if (id === 'bar') {
                   myChart.lineMax = currentMax;
                   updateSliderHandlePosition(sliderGroup,sliderClass, id, 'max', newPos);
                   updateTrackInset(sliderGroup,scale, sliderClass, id, min, myChart.lineMax);
                   drawChart();
                 }
               }
             }
             function updateTrackInset(sliderGroup:any,scale: any, sliderClass: string, id: string, min: number, max: number): void {
               sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
                 .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
                 .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max));
             }
             
             function updateSliderHandlePosition( sliderGroup:any,sliderClass: string, id: string, handleType: string, newPos: number): void {
               sliderGroup.select(`.${sliderClass}.${id}-${handleType}-handle`)
                 .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', newPos);
                 sliderGroup.select(`.${sliderClass}.${id}-${handleType}-hover-layer`)
                 .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', newPos);
             }
    
    function brushed(event: any): void {

      const selection = event.selection;
      if (selection !== null) {
        // hide tooltip
        myChart.hideTooltip('averageDot');
        // calculate xDomain based on selection
        const xDomain: any = selection.map(xScaleAll.invert);
        const dataRemaining: any = chartData.filter((f: any) => f.date >= xDomain[0] && f.date <= xDomain[1]);
        if(dataRemaining.length < 1) {
          // if no data in selection (zoomed too far), reset to previous selection
          const previousStart = xScaleAll(xScale.domain()[0]);
          const previousEnd = xScaleAll(xScale.domain()[1]);
          if(previousStart !== undefined && previousEnd !== undefined){
            mySvg.select('#brushGroup' + myClass)
              //@ts-ignore
              .call(brush.move, myChart.props.showBrush === false ? null : [previousStart, previousEnd]);
          }
        } else {
          // set partial period visibility
          // only show if we are at the end of the dataset
          if(String(xDomain[1]) === String(xScaleAll.domain()[1])){
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility", myChart.props.partialPeriod === true ? "visible" : "hidden")
          } else {
            mySvg.select('.partialPeriodLine' + myClass)
              .attr("visibility",  "hidden")
            mySvg.select('.partialPeriodLineLabel' + myClass)
              .attr("visibility","hidden")
          }
          // reset selected scale
          xScale.domain(xDomain);
          // reset xAxis
          mySvg.select('#xAxis' + myClass)
            .call(
              d3.axisBottom(xScale)
                .tickSizeOuter(0).tickFormat(xTickFormat).ticks(tickCount)
              .tickValues(tickValues === null ? null : tickValues.filter((f: any) => f >= xDomain[0] && f <= xDomain[1])))
            .attr('transform',  'translate(' + margins.left + ',' + (margins.top + chartHeight) + ')');
          // draw chart
          drawChart()
          }
      }
    }

    function measureWidth(myText: any, myFontSize: any): any {
      // used to measure width and therefore calculate where to place things
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }

    function wrap(text: any, width: any): void {
      // wraps to multi lines, from Observable/Mike Bostock
      text.selectAll('tspan').remove();
      let lineNumber = 0;
      const originalValue = text.text();
      text.each(function(): any {
        // @ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 1.1; // ems
        const dy = 0;
        let tspan = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'em');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          // @ts-ignore
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(' '));
            line = [word];
            if (word.trim().length > 0){
              tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);

            }
          }
        }
      });
      // custom code to add .. rather than go to 2 lines (requested at some point)
      if (lineNumber > 1){
        text.selectAll('tspan').remove();
        text.attr('id', originalValue)
        text.text(originalValue.substr(0, 5) + '..');
      }
    }
    this.stop(10).then(() => this.isLoading = false);
  }

// numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
  }
}

