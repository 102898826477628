import { Component, ElementRef, HostBinding, HostListener, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as d3 from "d3";
import { mark } from "@angular/compiler-cli/src/ngtsc/perf/src/clock";
import { FilterService } from 'src/app/services/filter.service';
import { CurrencyPipe } from '@angular/common';
@Injectable({
  providedIn: 'root'
})
@Component({
  selector: 'app-small-bar-chart',
  templateUrl: './small-bar-chart.component.html',
  styleUrls: ['./small-bar-chart.component.scss']
})
export class SmallBarChartComponent implements OnInit, OnChanges {
  @ViewChild('smallBarContainer', { static: true }) smallBarContainer!: ElementRef
  @ViewChild('smallBarChart') smallBarChart!: ElementRef;
  @Input('data') data: any
  @Input('pageKey') pageKey: any
  @Input('item') item: any
  @Input('config') config: any
  @Input('headerConfig') headerConfig: any
  @Input('heading') heading: string = ''
  @ViewChild('fs') fs!: ElementRef;
  isActive = false;
  iconList: any[] = []
  @HostBinding('class.is-fullscreen') isFullscreen = false;
  SmallBarChartData: any;
  props: any;
  divId: any = "SmallBarChartDiv";
  mytooltipData: any
  initiateChart: Boolean = false
  isTooltip: Boolean = false
  noData: Boolean = false
  dataTurn: any
  myType: any | undefined
  //sider Variables
private sliderSize = 300;
private handleRadius = 6;
private currentMin = 0;
private currentMax = 100;
private margins = {left:10,top:20}
private orientation = 'vertical';
  @HostListener('fullscreenchange', ['$event'])
  @HostListener('webkitfullscreenchange', ['$event'])
  @HostListener('mozfullscreenchange', ['$event'])
  @HostListener('MSFullscreenChange', ['$event'])
  screenChange(event: any) {
    if (this.isFullscreen == true) {
      this.closeFullscreen();
      // this.isFullscreen = false
    }
  }

  constructor(private filterService: FilterService, private currency: CurrencyPipe) {
    this.filterService.filterQuery.subscribe((query: any) => {
      this.start()
    })
  }



  //loade Method
  isLoading = false;
  async stop(ms: number): Promise<void> {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
  }
  start() { this.isLoading = true; }

  ngOnInit(): void {
    this.start()
    this.iconList = this.item.config.icon ? this.item.config.icon : this.iconList
    this.divId = this.item.config.divid
    this.initiateCharts();

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data'].currentValue != changes['data'].previousValue && this.initiateChart) {
      this.getSmallBarChartData();
    }
  }
  closeFullscreen(): void {
    this.isFullscreen = false;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
    this.props.chartHeight = (this.item.config.list_of_api == 100609) ? 230 : 500
    this.isActive = false;
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    setTimeout(() => {
      this.plotChart()
    }, 100);
  }

  height: any = 0
  private showTooltip(myType: any, myData: any, myX: any, myY: any, chartWidth: any, chartHeight: any): void {
    this.myType = myType
    this.dataTurn = chartWidth - myX
    this.mytooltipData = myData
    this.height = 0
    this.dataTurn = 0
    this.height = chartHeight - myY
    this.dataTurn = chartWidth - myX

    if (myType == 'groupFullName') {
      d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
        .style('visibility', 'visible')
        .style('position', 'absolute')
        .style('bottom', 'unset')
        .style('top', (myY+25) + 'px')

      if (this.dataTurn < 250) {
        d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
          .style('right', (this.dataTurn + 20) + 'px')
          .style('left', 'unset')
      }
      else if (this.dataTurn > 250) {
        d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
          .style('left', (myX + 25) + 'px')
          .style('right', 'unset')
      }

    }
    else {
      if (this.isFullscreen == true) {
        if (this.height < 200) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', (this.height) + 'px')
            .style('top', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height - 60) + 'px')
          }
          else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height - 60) + 'px')
          }
        }
        else if (this.height > 200) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            // .style('top', (myY + 25) + 'px')
            .style('bottom', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 40 + 'px')
          }
          else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 30 + 'px')
          }
        }

        if (this.dataTurn < 250) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }

        this.isTooltip = true
      }
      else {
        if (this.height < 200) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('bottom', (this.height) + 'px')
            .style('top', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height - 60) + 'px')
          } else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('bottom', (this.height - 60) + 'px')
          }
        }
        else if (this.height > 200) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            // .style('top', (myY + 25) + 'px')
            .style('bottom', 'unset')

          if (this.mytooltipData.length == undefined) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 40 + 'px')
          } else if (this.mytooltipData.length > 0) {
            d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
              .style('top', myY + 30 + 'px')
          }
        }

        if (this.dataTurn < 250) {
          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('right', (this.dataTurn + 20) + 'px')
            .style('left', 'unset')
        }
        else if (this.dataTurn > 250) {

          d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
            .style('visibility', 'visible')
            .style('position', 'absolute')
            .style('left', (myX + 25) + 'px')
            .style('right', 'unset')
        }

      }
    }
    this.isTooltip = true

  }

  private hideTooltip(myType: any): void {
    this.isTooltip = false
    // d3.select(this.smallBarChart.nativeElement).select("#d3SBarTooltip")
    // .style('visibility', 'hidden');
  }

  //  d3 chart initial structure
  initiateCharts(): void {
    // only need to call this once on initialisation
    const myChart = this;
    const myClass = myChart.divId;

    const mySvg = d3.select(this.smallBarContainer.nativeElement)
      .append('svg')
      .attr('id', 'svg_' + myClass)
      .attr('width', '100%')
      .style('background-color', 'white');
      const defs = mySvg.append('defs');

      defs.append('clipPath').attr('id', 'brushClip' + myClass)
        .append('rect').attr('id', 'brushClipRect' + myClass);

    mySvg.append("g").attr("class", "xAxis" + myClass);
    mySvg.append("g").attr("class", "yAxis" + myClass);
    mySvg.append("g").attr("id", "chartGroup" + myClass);
    mySvg.append('g').attr('id', 'sliderGroup' + myClass).attr('class', 'slider' + myClass);
    this.initiateChart = true
  }
  // chart svg  plotChart rendering 
  plotChart(): void {
    const myChart:any = this;
    const myClass = myChart.divId;
    const mySvg: any = d3.select('#svg_' + myClass);
    const width: any = mySvg.node().getBoundingClientRect().width;
    const height = this.props.chartHeight;
    let margins = { left: 45, right: 12, top: 40, bottom: 48 };

    margins.top = !myChart.props.showLegend && myChart.props.stackVar === null ? 15 : 40;
    mySvg.attr("height", height);
    const chartHeight = height - margins.top - margins.bottom ;
    let yMax: any = undefined;
    let yScale: any = undefined;
    let yScaleHeight: any = undefined;
    const tickFormat: any = {"D": "%d %b %y", "W": "%d %b %y", "M": "%b %Y", "Q": "%b %Y", "Y": "%Y"};
    const timePeriod = this.props.period;
    const xTickFormat: any = d3.timeFormat(tickFormat[timePeriod]);
   
    myChart.SmallBarChartData.map((m: any) => m[myChart.props.yVar] = isNaN(m[myChart.props.yVar]) ? 0 : m[myChart.props.yVar])
    let barData: any = [];
    if (myChart.props.stackVar === null) {
      barData = d3.rollup(myChart.SmallBarChartData, (v: any) => d3.sum(v, (s: any) => s[myChart.props.yVar]), (d: any) => d[myChart.props.barVar])
      barData = Array.from(barData);
      barData = barData.map((m: any) => m = {
        name: m[0],
        value: m[1],
        start: 0,
        fill: myChart.props.barFill[myChart.props.yVar],
        borderRadius: 4
      });
      yMax = d3.max(barData, (d: any) => d.value+5);
      yScale = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom, 0]);
      yScaleHeight = d3.scaleLinear().domain([0, yMax]).range([0, height - margins.top - margins.bottom]);
      barData.map((m: any) => m.height = yScaleHeight(m.value))
      barData.map((m: any) => m.borderRadius = 2);
      barData.map((m: any) => m.totalValue = m.value);
      barData.map((m: any) => m.extraY = 0);
    } else {
      let barVars: any = new Set();
      let stackVars: any = new Set()
      myChart.SmallBarChartData.forEach((d: any) => {
        barVars.add(d[myChart.props.barVar]);
        stackVars.add(d[myChart.props.stackVar]);
      })
      let stackData: any = [];
      Array.from(barVars).forEach((d: any) => {
        const myData: any = { name: d }
        Array.from(stackVars).forEach((v: any) => {
          const myValue = d3.sum(myChart.SmallBarChartData, (s: any) =>
            s[myChart.props.barVar] === d && s[myChart.props.stackVar] === v
              ? s[myChart.props.yVar] : 0);
          myData[v] = myValue;
        })
        stackData.push(myData);
      })
      stackData = d3.stack()
        .keys(Array.from(stackVars))
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone)
        (stackData);


      yMax = d3.max(stackData, (d: any) => d3.max(d, (m: any) => m[1]));
      yScale = d3.scaleLinear().domain([0, yMax]).range([height - margins.top - margins.bottom, 0]);
      yScaleHeight = d3.scaleLinear().domain([0, yMax]).range([0, height - margins.top - margins.bottom]);
      const extraYs: any = {};
      stackData.forEach((d: any, i: any) => {
        const myKey = d.key;
        const isLast = (i === (stackData.length - 1));
        d.map((m: any) => m.value = (m[1] - m[0]))
        d.map((m: any) => m.height = yScaleHeight(m.value));
        d.forEach((s: any, index: any) => {
          let myExtraY = 0;
          if (s.height < 2 && s.value > 0) {
            myExtraY = (2 - s.height);
            s.height = 2;
            extraYs[index] = (extraYs[index] === undefined ? myExtraY : extraYs[index] + myExtraY)
          }
          let myFill = myChart.props.barFill[myKey];
          if (myFill === undefined) {
            myFill = myChart.props.stackColors[i];
            myChart.props.barFill[myKey] = myFill;
            myChart.props.barLabels[myKey] = myKey;
          }
          barData.push({
            name: s.data.name,
            start: s[0],
            value: s.value,
            height: s.height,
            fill: myFill,
            extraY: extraYs[index] === undefined ? 0 : -extraYs[index],
            totalValue: s[1],
            borderRadius: isLast ? 2 : 0,
            groupIndex: index,
            stackIndex: i,
            errorType: myKey
          })
        })
      })
    }

    const emptyLastBars = barData.filter((f: any) => f.borderRadius > 0 && f.value === 0);
    emptyLastBars.forEach((d: any) => {
      d.borderRadius = 0;
      let filledBars = barData.filter((f: any) => f.groupIndex === d.groupIndex && f.value > 0);
      filledBars = filledBars.sort((a: any, b: any) => d3.descending(a.stackIndex, b.stackIndex));
      if (filledBars.length > 0) {
        filledBars[0].borderRadius = 2;
      }
    })
    const xTicks: any = [];
    barData.forEach((d: any) => xTicks.push(d.name));


     // define clip rect dimensions
     mySvg.select('#brushClipRect' + myClass)
     .style('width', width - margins.left - margins.right)
     .style('height', chartHeight  )
     .attr('transform', 'translate(' + margins.left + ','+ margins.top +')');

    const xScale: any = d3.scaleBand().domain(xTicks).range([0, width - margins.left - margins.right]);

    mySvg.select('.xAxis' + myClass)
      // @ts-ignore
      .call(d3.axisBottom(xScale).tickSizeOuter(0).tickValues(xTicks))
      .attr('transform', 'translate(' + margins.left + ',' + (height - margins.bottom) + ')');

    mySvg.select('.yAxis' + myClass)
      // @ts-ignore
      .call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5))
      .attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');

    mySvg.selectAll('.xAxis' + myClass + ' text')
      .attr('dy', 4)
      .style('font-weight', 400)
      .style('font-family', "Poppins")
      .style('fill', "#101D42")
      .style('font-size', 10)
      // .style('font-size',this.props.XaxisDate ?  10 : 10)
      .text((d: any) =>  this.props.XaxisDate ? (xTickFormat)(new Date(d)) : getXLabel(d.replace(/_/g, ' '), xScale.bandwidth()))
      // .text((d: any) => getXLabel(d.replace(/_/g, ' '), xScale.bandwidth()))
      .on("mouseover", (event: any, d: any) => {
        if (getXLabel(d.replace(/_/g, ' '), xScale.bandwidth()).includes("..")) {
          myChart.showTooltip('groupFullName', d.replace(/_/g, ' '), event.offsetX, event.offsetY, width, height);
        }
      })
      .on("mouseout", () => {
        myChart.hideTooltip("groupFullName");
      });
      if (myChart.props.XaxisDate) {
        mySvg.selectAll('.xAxis' + myClass + ' text').call(wrap,20)}

    mySvg.selectAll('.yAxis' + myClass + ' text')
      .style('font-weight', 400)
      .style('font-family', "Poppins")
      .style('fill', "#8A98AB")
      .style('font-size', 8)

    mySvg.selectAll('.yAxis' + myClass + ' line')
      .attr('x1', 0)
      .attr('x2', width - margins.left - margins.right)
      .style('stroke', "#E8EAEE")
      .style('stroke-width', 1);

    mySvg.selectAll('.yAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.xAxis' + myClass + ' path')
      .style('display', "none")

    mySvg.selectAll('.xAxis' + myClass + ' line')
      .style('display', "none")

    if (myChart.props.stackVar !== null || myChart.props.showLegend) {
      drawLegend(Object.keys(myChart.props.barFill));
    }
//     mySvg.select('#chartGroup' + myClass).remove(); 
//     const barGroup = mySvg.append('g')
//     .attr('id', 'chartGroup' + myClass)
//     .selectAll('.chartGroup' + myClass)
//       .data(barData)
//       .join((group: any) => {
//         const enter = group.append('g').attr('class', 'chartGroup' + myClass);
//         enter.append('path').attr('class', 'barRect');
//         enter.append('text').attr('class', 'barValue');
//         return enter;
//       });

//     const barWidth = xScale.bandwidth() / 4;
// if (myChart.props.barText) {
     
//     barGroup.select('.barValue')
//     .attr('fill',(d: any) => d.fill )
//     // .attr('x',barWidth/4)
//     .style("font-size", barWidth)
//     .style("font-family", "Poppins")
//     .style("font-weight", 500)
//     .text((d:any)=> d.totalValue)
//     .attr('transform',(d: any) => 'translate(' + (margins.left + xScale(d.name) + (xScale.bandwidth() * 0.375))
//     + ',' + (margins.top + (yScale(d.totalValue) + d.extraY-5)) + ')');
//   }

//     barGroup.select('.barRect')
//       .attr('d', (d: any) => 'M0,' + d.height + 'L0,' + d.borderRadius
//         + ' Q0,0 ' + d.borderRadius + ',0 L' + (barWidth - d.borderRadius)
//         + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + d.borderRadius
//         + ' L' + barWidth + ',' + d.height + 'Z')
//       .attr('fill', (d: any) => d.fill)
//       .attr('stroke-width', 0)
//       .attr('transform', (d: any) => 'translate(' + (margins.left + xScale(d.name) + (xScale.bandwidth() * 0.375))
//         + ',' + (margins.top + (yScale(d.totalValue) + d.extraY)) + ')')
//       .on("mouseover", (event: any, d: any) => {
//         let tooltipData = "";
//         if (myChart.props.stackVar === null) {
//           tooltipData = d;
//         } else {
//           tooltipData = barData.filter((f: any) => f.name === d.name);
//         }
//         myChart.showTooltip('bar', tooltipData, event.offsetX, event.offsetY, width, height);
//       })
//       .on("mouseout", () => {
//         myChart.hideTooltip('bar');
//       });
 // draw chart
 const barGroup = mySvg.select('#chartGroup' + myClass)
   .selectAll('.chartGroup' + myClass)
   .data(barData)
   .join((group: any) => {
     const enter = group.append('g').attr('class', 'chartGroup' + myClass);
     enter.append('path').attr('class', 'barRect');
     enter.append('text').attr('class', 'barValue');
     return enter;
   });
     barGroup.attr('clip-path', (d: any) =>  'url(#brushClip' + myClass + ')');
 drawChart();
 function drawChart(){


  const yScale       = d3.scaleLinear().domain([myChart.lineMin || 0 , myChart.lineMax || Number(yMax) ]).range([height - margins.top - margins.bottom, 0]);
   yScaleHeight = d3.scaleLinear().domain([myChart.lineMin || 0 , myChart.lineMax || Number(yMax) ]).range([0, height - margins.top - margins.bottom]);
  
   mySvg.select('.yAxis' + myClass).call(d3.axisLeft(yScale).tickSizeOuter(0).ticks(5)).attr('transform', 'translate(' + margins.left + ',' + margins.top + ')');
   
   
 
 const barWidth = xScale.bandwidth() / 4;
if (myChart.props.barText) {
  
 barGroup.select('.barValue')
 .attr('fill',(d: any) => d.fill )
 // .attr('x',barWidth/4)
 .style("font-size", barWidth)
 .style("font-family", "Poppins")
 .style("font-weight", 500)
 .text((d:any)=> d.totalValue)
 .attr('transform',(d: any) => 'translate(' + (margins.left + xScale(d.name) + (xScale.bandwidth() * 0.375))
 + ',' + (margins.top + (yScale(d.totalValue) + d.extraY-5)) + ')');
}

 barGroup.select('.barRect')
   .attr('d', (d: any) =>{ 
    let barHeight = yScale(0) - yScale(d.value)
    barHeight = barHeight < 3 && barHeight !== 0 ? 3 : barHeight;
     return 'M0,' +( barHeight) + 'L0,' + d.borderRadius
     + ' Q0,0 ' + d.borderRadius + ',0 L' + (barWidth - d.borderRadius)
     + ',0 Q' + barWidth + ',0 ' + barWidth + ',' + d.borderRadius
     + ' L' + barWidth + ',' + ( barHeight) + 'Z'})
   .attr('fill', (d: any) => d.fill)
   .attr('stroke-width', 0)
   .attr('transform', (d: any) => 'translate(' + (margins.left + xScale(d.name) + (xScale.bandwidth() * 0.375))
     + ',' + (margins.top + (yScale(d.totalValue) + d.extraY)) + ')')
   .on("mouseover", (event: any, d: any) => {
     let tooltipData = "";
     if (myChart.props.stackVar === null) {
       tooltipData = d;
     } else {
       tooltipData = barData.filter((f: any) => f.name === d.name);
     }
     myChart.showTooltip('bar', tooltipData, event.offsetX, event.offsetY, width, height);
   })
   .on("mouseout", () => {
     myChart.hideTooltip('bar');
   });
  }
    
 const slider = mySvg.select('#sliderGroup' + myClass);

    // // Bar Slider
    // myChart.barMin = myChart.currentMin;
    // myChart.barMax = sbarMax;
    // createRangeSlider('bar', myChart.currentMin, sbarMax);
    
    // Line Slider 
    if(myChart.props.slider){
    myChart.lineMin = myChart.currentMin;
    myChart.lineMax = yMax;
    createRangeSlider('bar', myChart.currentMin, yMax);
    }
    


        function createRangeSlider(id: string, min: number, max: number): void {
           const sliderGroupId = `sliderGroup-${id}`;
           const sliderClass = `${id}-slider`;
         
           // Remove any existing slider group for this ID
           slider.select(`#${sliderGroupId}`).remove();
         
           // Create a new slider group
           const sliderGroup = slider.append('g').attr('id', sliderGroupId);
           // Scale setup for the slider
           const scale = d3.scaleLinear()
             .domain([min, max])
             .range(myChart.orientation === 'horizontal' ? [0, yScale.range()[0]] : [yScale.range()[0], 0])
             .clamp(true);
         
           // Drag event handlers for the min and max handles
           const dragMinHandle = d3.drag().on('drag', (event) => onDragMinHandle( sliderGroup,event, scale, sliderClass, id, min, max));
           const dragMaxHandle = d3.drag().on('drag', (event) => onDragMaxHandle( sliderGroup,event, scale, sliderClass, id, min, max));
         
           // Create the slider track and inset line
           sliderGroup.append('line')
             .attr('class', `${sliderClass} ${id}-track`)
             .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
             .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max))
             .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
             .attr('stroke', '#ddd')
             .attr('stroke-width', 1);
         
           sliderGroup.append('line')
             .attr('class', `${sliderClass} ${id}-track-inset`)
             .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
             .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max + margins.top))
             .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
             .attr('stroke', '#ddd')
             .attr('stroke-width', 1);
         
           // Tooltip setup
           const tooltip = d3.select('body').append('div')
             .attr('class', 'tooltip')
             .style('position', 'absolute')
             .style('padding', '5px')
             .style('background', '#fff')
             .style('border', '1px solid #ccc')
             .style('border-radius', '4px')
             .style('pointer-events', 'none')
             .style('opacity', 0);
         
           // Create Min and Max handles with hover and drag functionality
           createSliderHandle( sliderGroup,sliderClass, id, 'min', min, scale, dragMinHandle, tooltip);
           createSliderHandle(sliderGroup,sliderClass, id, 'max', max, scale, dragMaxHandle, tooltip);
         }
   
         function createSliderHandle(sliderGroup:any,sliderClass: string, id: string, handleType: string, value: number, scale: any, dragHandle: any, tooltip: any): void {
           const handle = sliderGroup.append('circle')
             .attr('class', `${sliderClass} ${id}-${handleType}-handle`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', scale(value))
             .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
             .attr('r', myChart.handleRadius)
             .attr('fill', '#69b3a2')
             .call(dragHandle);
         
           // Hover effect layer
           const hoverLayer = sliderGroup.append('circle')
             .attr('class', `${sliderClass} ${id}-${handleType}-hover-layer`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', scale(value))
             .attr('transform', `translate(${id === 'line' ? (width - margins.left + 40) : (margins.left )}, ${margins.top})`)
             .attr('r', myChart.handleRadius + 8)
             .attr('fill', '#69b3a2')
             .attr('opacity', 0)
             .attr('pointer-events', 'none');
         
           // Handle hover effect
           handle.on('mouseover', function(event: any) {
             hoverLayer.transition().duration(200).attr('opacity', 0.4);  // Show hover layer
             // sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
             //         .attr('stroke-width', 4)
             //         .attr('stroke', '#69b3a2');  // Change color on hover
               //  tooltip
               // .style('opacity', 1)
               // .html(`Max: ${id === 'bar' ? myChart.barMax : myChart.lineMax}`)
               // .style('left', `${event.pageX + 10}px`)
               // .style('top', `${event.pageY - 20}px`);
           })
           .on('mouseout', function() {
             hoverLayer.transition().duration(200).attr('opacity', 0);  // Hide hover layer
             // sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
             //         .attr('stroke-width', 1)
             //         .attr('stroke', '#ddd');  // Revert to original color
                     // tooltip.style('opacity', 0);  // Hide tooltip on mouse out
           })
           .on('mousemove', function(event: any) {
             // Update tooltip position
             //  tooltip
             //   .style('opacity', 1)
             //   .html(`Max: ${id === 'bar' ? myChart.barMax : myChart.lineMax}`)
             //   .style('left', `${event.pageX + 10}px`)
             //   .style('top', `${event.pageY - 20}px`);
             // tooltip.style('left', `${event.pageX + 10}px`).style('top', `${event.pageY - 20}px`);
           });
         }
         
         function onDragMinHandle(sliderGroup:any,event: any, scale: any, sliderClass: string, id: string, min: number, max: number): void {
           const newPos = Math.max(scale(max), event[myChart.orientation === 'horizontal' ? 'x' : 'y'] - margins.top);
           const currentMin = Math.round(scale.invert(newPos) - 1);
           if (currentMin >= min && currentMin <= (id === 'line' ? myChart.barMax - 5 : myChart.lineMax - 5)) {
             if (id === 'line') {
               myChart.barMin = currentMin;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'min', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, myChart.barMin, max);
               drawChart();
             } else if (id === 'bar') {
               myChart.lineMin = currentMin;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'min', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, myChart.lineMin, max);
               drawChart();
             }
           }
         }
         
         function onDragMaxHandle(sliderGroup:any,event: any, scale: any, sliderClass: string, id: string, min: number, max: number): void {
           const newPos = Math.min(scale(min), event[myChart.orientation === 'horizontal' ? 'x' : 'y'] - margins.top);
           const currentMax = Math.round(scale.invert(newPos) + 1);
           if (currentMax <= max && currentMax >= (id === 'line' ? myChart.barMin + 5 : myChart.lineMin + 5)) {
             if (id === 'line') {
               myChart.barMax = currentMax;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'max', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, min, myChart.barMax);
               drawChart();
             } else if (id === 'bar') {
               myChart.lineMax = currentMax;
               updateSliderHandlePosition(sliderGroup,sliderClass, id, 'max', newPos);
               updateTrackInset(sliderGroup,scale, sliderClass, id, min, myChart.lineMax);
               drawChart();
             }
           }
         }
         function updateTrackInset(sliderGroup:any,scale: any, sliderClass: string, id: string, min: number, max: number): void {
           sliderGroup.select(`.${sliderClass}.${id}-track-inset`)
             .attr(myChart.orientation === 'horizontal' ? 'x1' : 'y1', scale(min))
             .attr(myChart.orientation === 'horizontal' ? 'x2' : 'y2', scale(max));
         }
         
         function updateSliderHandlePosition( sliderGroup:any,sliderClass: string, id: string, handleType: string, newPos: number): void {
           sliderGroup.select(`.${sliderClass}.${id}-${handleType}-handle`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', newPos);
             sliderGroup.select(`.${sliderClass}.${id}-${handleType}-hover-layer`)
             .attr(myChart.orientation === 'horizontal' ? 'cx' : 'cy', newPos);
         }



    function drawLegend(legendData: any) {

      const legendGroup = mySvg
        .selectAll('.legendGroup' + myClass)
        .data(legendData)
        .join((group: any) => {
          const enter = group.append('g').attr('class', 'legendGroup' + myClass);
          enter.append('circle').attr('class', 'legendCircle');
          enter.append('text').attr('class', 'legendLabel');
          return enter;
        });

      legendGroup.attr("transform", "translate(" + (margins.left + 36) + ",15)");

      legendGroup.select('.legendCircle')
        .attr('id', (d: any) => 'circle_legendItem' + d)
        .attr('fill', (d: any) => myChart.props.barFill[d])
        .attr('r', 4);

      legendGroup.select('.legendLabel')
        .attr('id', (d: any) => 'legendItem' + d)
        .attr('y', 5)
        .attr('fill', '#101D42')
        .attr('font-weight', '500')
        .attr('font-size', '12')
        .attr('font-family', 'Poppins')
        .text((d: any) => myChart.props.barLabels[d].toUpperCase())

      let legendX = 0;
      mySvg.selectAll('.legendLabel').each(function (): any {
        //@ts-ignore
        const myObject = this;
        const myWidth: any = document.getElementById(myObject.id)?.getBoundingClientRect().width;
        d3.select(myObject).attr('x', legendX + 12);
        mySvg.select('#circle_' + myObject.id).attr('cx', legendX);
        legendX += (40 + myWidth)
      });

    }

    function getXLabel(myLabel: any, bandwidth: any) {
      while (!checkWidth(myLabel) || myLabel === "..") {
        if (myLabel.includes("..")) {
          myLabel = myLabel.substr(0, myLabel.length - 2);
        }
        myLabel = myLabel.substr(0, myLabel.length - 1) + "..";
      }
      return myLabel;
      function checkWidth(myText: any) {
        if ((measureWidth(myText, 12) + 4) > bandwidth) {
          return false
        } else {
          return true
        }
      }
    }

    function measureWidth(myText: any, myFontSize: any): any {
      const context: any = document.createElement('canvas').getContext('2d');
      context.font = myFontSize + 'px sans-serif';
      return context.measureText(myText).width;
    }

    function wrap(text: any, width: any): void {
      text.selectAll('tspan').remove();

      text.each(function (): any {
        let lineNumber = 0;
        //@ts-ignore
        const text = d3.select(this);
        const words = text.text().split(/\s+/).reverse();
        let word = null;
        let line: any = [];
        const lineHeight = 10;
        const dy = 15;
        let tspan: any = text.text(null).append('tspan').attr('x', 0).attr('y', 0).attr('dy', dy + 'px');
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            if (line.length > 0) {
              tspan.text(line.join(' '));
              line = [word];
              if (word.trim().length > 0) {
                lineNumber += 1;
                tspan = text.append('tspan').attr('x', 0).attr('y', 0).attr('dy', lineNumber * lineHeight + dy + 'px').text(word);

              }
            } else {
              line = [word]
            }
          }
        }
      });
    }
    this.stop(10).then(() => this.isLoading = false);
  }
  // property & data for the chart
  getSmallBarChartData() {
    this.SmallBarChartData = this.data
// debugger
    if (this.item.config.chart_type == 'onebar') {
      if (this.item.config.list_of_api == 100609) {
        this.props = {
          chartHeight: 230,
          barVar: 'period',
          yVar: 'time_to_therapy',
          stackVar: null,
          stackColors: ['deeppink', 'blue', 'gold'],
          barFill: {"time_to_therapy": "#1363DF" },
          barLabels: {'time_to_therapy':"days"},
          showLegend: true,
          XaxisDate:true,
          barText:true,
          period:"M",
          slider:  this.item.config?.['zoom_slider_left']?.value=='true' || false,
        }
      //   this.SmallBarChartData =  [
      //     {
      //         "time_to_therapy": 12.5,
      //         "period": "2023-01-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 5,
      //         "period": "2023-02-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 2,
      //         "period": "2023-03-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 10,
      //         "period": "2023-04-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 9.5,
      //         "period": "2023-05-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 6,
      //         "period": "2023-06-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 6,
      //         "period": "2023-07-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 4,
      //         "period": "2023-08-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 3,
      //         "period": "2023-09-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 2,
      //         "period": "2023-10-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 2,
      //         "period": "2023-11-01T00:00:00",
      //     },
      //     {
      //         "time_to_therapy": 2,
      //         "period": "2023-12-01T00:00:00",
      //     }
      // ]
      } else {
        this.props = {
          chartHeight: 500,
          barVar: 'field_name',
          yVar: 'defect_count',
          stackVar: null,
          stackColors: ['deeppink', 'blue', 'gold'],
          barFill: { 'defect_count': '#8C33E6' },
          barLabels: {},
          showLegend: false,
          XaxisDate:false,
          barText:false,
          slider:this.item.config?.['zoom_slider_left']?.value=='true' || false,
        }
      }
      // this.props = {
      //   chartHeight: 500,
      //   barVar: 'field_name',
      //   yVar: 'defect_count',
      //   stackVar:  null , //single bar if stackVar === null
      //   barFill:  {'defect_count':'#8C33E6'},
      //   barLabels:  {'defect_count':'DEFECT count'}


      // }
    } else {
      this.props = {
        chartHeight: 500,
        barVar: 'data_source_name',
        yVar: 'defect_count',
        stackVar: 'error_type', //single bar if stackVar === null
        barFill: { 'E': '#FFD953', 'F': '#FF842B' }, //'W': "deeppink","FP" : "skyblue",
        barLabels: { 'E': 'Errors', 'F': 'Failures', },//'W': "Warnings","FP" : "Failures But Processed"
        showLegend: true,
        XaxisDate:false,
        barText:false,
        slider:  this.item.config?.['zoom_slider_left']?.value=='true' || false,
      }
    }
  //   this.props ={
  //     chartHeight: 500,
  //     barVar: "period",
  //     yVar: "defect_count",
  //     stackVar: null,
  //     stackColors:  ['deeppink', 'blue', 'gold'],
  //     barFill: {"defect_count": "#1363DF" },
  //     barLabels: {'defect_count':"days"},
  //     showLegend: true,
  //     XaxisDate:true, 
  //     barText:true,
  //     period:'M'
  // }
    // @ts-ignore
    if (this.SmallBarChartData.length > 0) {
      this.noData = false
      setTimeout(() => {
        this.plotChart();
      }, 100);
    } else {
      this.noData = true
      this.stop(10).then(() => this.isLoading = false);
    }

    // });
    // this.plotChart();
  }
  // numberFormat
  numbedPipe(value: any) {
    return this.currency.transform(value, '', '', '1.0-2');
    //  return   this.numberpipe.transform(value)
  }
  // removeUnderScore from the text
  removeUnderScore(value: string) {
    return value.replace(/_/g, ' ').toUpperCase()
  }
  errorType(errorType: any) {
    switch (errorType) {
      case 'E': return "Errors"
      case 'F': return "Failures"
      case 'W': return "Warnings"
      case 'FP': return "Failures But Processed"


    }

  }
}
